import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import SendOutApi from "../api/bo/SendOutApi"
import { jsPDF } from "jspdf"
import autoTable, { Color, FontStyle } from "jspdf-autotable"
import moment from "moment"
import SignatureType from "../api/client/models/SignatureType"
import ISendOutCreateRequest from "../api/bo/models/sendout/ISendOutCreateRequest"
import ISendOutUpdateRequest from "../api/bo/models/sendout/ISendOutUpdateRequest"

export default class SendOutService {
	public static getAllForCase(caseId: string) {
		return SendOutApi.getAllForCase(caseId).then(sendOuts => {
			return sendOuts
		})
	}
	
	public static getByKey(key: string) {
		return SendOutApi.getByKey(key).then(sendOut => {
			return sendOut
		})
	}
	
	public static create(
		caseId: string,
		questionnaireId: string,
		description: string,
		receiverTypeId?: string,
		signatureType?: SignatureType,
	) {
		const data = {
			caseId,
			questionnaireId,
			description,
			receiverTypeId,
			signatureType,
		} as ISendOutCreateRequest

		return SendOutApi.create(data).then(key => {
			return key
		})
	}
	
	public static update(
		key: string,
		description: string,
		receiverTypeId?: string,
	) {
		const data = {
			description,
			receiverTypeId,
		} as ISendOutUpdateRequest

		return SendOutApi.update(key, data).then(key => {
			return key
		})
	}
	
	public static delete(id: string) {
		return SendOutApi.delete(id)
	}

	public static downloadPdf(
		calculation: {
			columns: GridColDef<any>[],
			rows: {[key: string]: string}[],
		},
		baseInfo: {
			label: string,
			value: string,
		}[],
		answersData: {
			questionId: string,
			questionOrder: number,
			questionText: string,
			questionIdentifier?: string,
			answerValue?: number,
			answerText: string,
		}[],
	) {
		// Default export is a4 paper, portrait, using millimeters for units
		const pdf = new jsPDF()

		const headerStyle = {
			fillColor: [41, 128, 185] as [number, number, number],
			textColor: 255 as Color,
			fontStyle: "bold" as FontStyle,
		}

		// pdf.autoTable()

		const baseRows: {[key: string]: string}[] = []

		for (let i in baseInfo) {
			const baseRow: {[key: string]: string} = {}
			const row = baseInfo[i]

			baseRow["label"] = row.label
			baseRow["value"] = row.value

			baseRows.push(baseRow)
		}

		autoTable(pdf, {
			head: [{
				label: "",
				value: "",
			}],
			body: baseRows,
			showHead: false,
			columnStyles: {
				label: headerStyle,
			},
		})

		if (calculation.columns.length) {
			const calcHeader: {[key: string]: any} = {}
	
			for (let i in calculation.columns) {
				const col = calculation.columns[i]
	
				calcHeader[col.field] = col.headerName || ""
			}
	
			autoTable(pdf, {
				head: [calcHeader],
				body: calculation.rows,
				// didDrawCell: (data) => { },
			})
		}

		if (answersData.find(x => x.questionIdentifier)) {
			const questionIdentifierWithAnswerValueHeaders = {
				questionIdentifier: "Fråga",
				answerValue: "Svar",
			}
	
			autoTable(pdf, {
				head: [questionIdentifierWithAnswerValueHeaders],
				body: answersData,
				// horizontalPageBreak: true,
				// horizontalPageBreakRepeat: 0,
				// didDrawCell: (data) => { },
			})
		}

		const answersHeader = {
			questionOrder: "",
			questionText: "Fråga",
			answerValue: "Svar",
			answerText: "",
		}

		autoTable(pdf, {
			head: [answersHeader],
			body: answersData,
			// horizontalPageBreak: true,
			// horizontalPageBreakRepeat: 0,
			// didDrawCell: (data) => { },
		})

		pdf.save(moment().format("YYYYMMDDHHmmss"))
	}
}
