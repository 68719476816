import ICase from "./models/case/ICase"
import AdminBaseApi from "./AdminBaseApi"
import { RequestMethod } from "../RequestMethod"
import ICaseRequest from "./models/case/ICaseRequest"

export default class CaseApi extends AdminBaseApi {
	protected static baseUri = "case"

	public static getAll() {
		return this.fetch<ICase[]>(undefined, RequestMethod.GET)
	}

	public static getOne(caseId: string) {
		return this.fetch<ICase>(undefined, RequestMethod.GET, undefined, [ caseId ])
	}

	public static isIdentifierTaken(identifier: string) {
		return this.fetch<boolean>(undefined, RequestMethod.GET, undefined, [ identifier ])
	}

	public static create(data: ICaseRequest) {
		return this.fetch<string>(undefined, RequestMethod.POST, data)
	}

	public static update(caseId: string, data: ICaseRequest) {
		return this.fetch(undefined, RequestMethod.PUT, data, [ caseId ])
	}

	public static close(caseId: string) {
		return this.fetch<string>("close", RequestMethod.GET, undefined, [ caseId ])
	}

	public static delete(caseId: string) {
		return this.fetch(undefined, RequestMethod.DELETE, undefined, [ caseId ])
	}
}
