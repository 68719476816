import React from "react"
import { Link, Tab } from "@mui/material"

export interface IATabProps {
	label: string
	action?: (value?: string) => void
	value?: string
	disabled?: boolean
	// color?: "inherit"
	// 	| "primary"
	// 	| "secondary"
	// 	| "success"
	// 	| "error"
	// 	| "info"
	// 	| "warning"
}

class ATab extends React.Component<IATabProps> {
	constructor(props: IATabProps) {
		super(props)
	}

	onClick = (e: React.MouseEvent) => {
		if (this.props.action) {
			this.props.action(this.props.value)
		}
	}

	render() {
		return <Tab
			// LinkComponent={Link}
			// href={"#" + this.props.value}
			label={this.props.label}
			disabled={this.props.disabled}
			onClick={this.onClick}
		/>
	}
}

export default ATab
