import React from "react"

import ManageQuestion from "./ManageQuestion"
import IAnswer from "../../../api/bo/models/questionnaire/IAnswer"
import IQuestion from "../../../api/bo/models/questionnaire/IQuestion"
import Lister from "../../../components/lister/Lister"
import QuestionType from "../../../api/bo/models/questionnaire/QuestionType"

interface SortQuestionsProps {
	questions: IQuestion[]
	updateQuestions: (questions: IQuestion[], activeQuestionIndex?: number) => void
	setUseAnswersFrom: (order: number, questionId?: string) => void
	openAddQuestionDialog: () => void
	editQuestion: (order: number) => void
	activeQuestionOrder: number | undefined
	setActiveQuestionOrder: (order?: number) => void
	getActiveQuestion: () => IQuestion | undefined
	moveQuestion: (fromOrder: number, toOrder: number) => void
	deleteQuestion: (order: number) => void
	setQuestionText: (order: number, text: string) => void
	disabled?: boolean
}

interface SortQuestionsState {
}

class SortQuestions extends React.Component<SortQuestionsProps, SortQuestionsState> {
	constructor(props: SortQuestionsProps) {
		super(props)
	}

	setQuestionType = (order: number, type: QuestionType) => {
		const {
			questions,
			updateQuestions,
		} = this.props

		for (let i = 0; i < questions.length; i++) {
			if (questions[i].order === order) {
				questions[i].type = type

				switch (type) {
					case QuestionType.FreeText:
						questions[i].derivedAnswersId = undefined
						break
				}
			}
		}

		updateQuestions(questions)
	}

	setQuestionText = (order: number, text: string) => {
		const {
			questions,
			updateQuestions,
		} = this.props

		for (let i = 0; i < questions.length; i++) {
			if (questions[i].order === order) {
				questions[i].text = text
			}
		}

		updateQuestions(questions)
	}

	setQuestionIdentifier = (order: number, identifier?: string) => {
		const {
			questions,
			updateQuestions,
		} = this.props

		for (let i = 0; i < questions.length; i++) {
			if (questions[i].order === order) {
				questions[i].identifier = identifier
			}
		}

		updateQuestions(questions)
	}

	setAnswers = (order: number, answers: IAnswer[]) => {
		const {
			questions,
			updateQuestions,
		} = this.props

		for (let i = 0; i < questions.length; i++) {
			if (questions[i].order === order) {
				questions[i].answers = answers
			}
		}

		updateQuestions(questions)
	}

	changeOrderOfQuestions = (questions: IQuestion[]) => {
		const {
			updateQuestions,
		} = this.props

		for (let i = 0; i < questions.length; i++) {
			questions[i].order = i + 1
		}
		
		const orderedQuestions = questions.map(q => {
			return q
		})

		updateQuestions(orderedQuestions)
	}

	render() {
		const {
			questions,
			activeQuestionOrder,
			setActiveQuestionOrder,
			moveQuestion,
			deleteQuestion,
			setUseAnswersFrom,
			disabled,
		} = this.props
		
		return <Lister<IQuestion>
			items={questions}
			keyExtractor={(item) => item.order.toString()}
			selectedKeys={
				activeQuestionOrder !== undefined
				? [activeQuestionOrder.toString()]
				: []
			}
			labelExtractor={(item) => "#" + item.order + ": " + item.text}
			onStart={() => {
				setActiveQuestionOrder(undefined)
			}}
			onSort={this.changeOrderOfQuestions}
			onDelete={(item) => {
				deleteQuestion(item.order)
			}}
			onSelect={(items, item) => {
				let newOrder: number | undefined = undefined

				if (item != undefined && item.order != activeQuestionOrder) {
					newOrder = item.order
				}
				
				setActiveQuestionOrder(newOrder)
			}}
			activeContentRenderer={(item) => {
				return <ManageQuestion
					question={item}
					questions={questions}
					deleteQuestion={deleteQuestion}
					moveQuestion={moveQuestion}
					setQuestionType={this.setQuestionType}
					setQuestionText={this.setQuestionText}
					setQuestionIdentifier={this.setQuestionIdentifier}
					setUseAnswersFrom={setUseAnswersFrom}
					setAnswers={this.setAnswers}
					disabled={disabled}
				/>
			}}
			hideCheckbox
			singleSelect
			disabled={disabled}
			// itemStyle={{
			// 	margin: "4.25px 4.25px 4.25px 2px", // 8.5px 4.25px 4.25px",
			// }}
		/>
	}
}

export default SortQuestions
