import Wrapper from "../Wrapper"
import DeviceHelper from "../../helpers/DeviceHelper"

const StatsScreen = () => {
	const deviceInfo = DeviceHelper.getDeviceInfo()

	return (
		<Wrapper
			breadcrumbs={[
				{
					label: "Sysinfo",
				}
			]}
			hideMenu
		>
			<table
				style={{
					width: "100%",
				}}
			>
				<tbody>
					<tr key="env">
						<th
							style={{
								width: "20%",
								textAlign: "left",
							}}
						>Environment</th>
						<td>{process.env.NODE_ENV}</td>
					</tr>
					<tr key="publicurl">
						<th
							style={{
								width: "20%",
								textAlign: "left",
							}}
						>Public url</th>
						<td>{process.env.PUBLIC_URL}</td>
					</tr>
					<tr key="adminapi">
						<th
							style={{
								width: "20%",
								textAlign: "left",
							}}
						>Admin api url</th>
						<td>{process.env.REACT_APP_ADMIN_BASE_URL}</td>
					</tr>
					<tr key="clientapi">
						<th
							style={{
								width: "20%",
								textAlign: "left",
							}}
						>Client api url</th>
						<td>{process.env.REACT_APP_CLIENT_BASE_URL}</td>
					</tr>
					<tr key="devicetype">
						<th
							style={{
								width: "20%",
								textAlign: "left",
							}}
						>Device</th>
						<td>{deviceInfo.device.type + " " + deviceInfo.device.model + " " + deviceInfo.device.vendor}</td>
					</tr>
					<tr key="deviceos">
						<th
							style={{
								width: "20%",
								textAlign: "left",
							}}
						>OS</th>
						<td>{deviceInfo.os.name + " " + deviceInfo.os.version}</td>
					</tr>
					<tr key="devicebrowser">
						<th
							style={{
								width: "20%",
								textAlign: "left",
							}}
						>Browser</th>
						<td>{deviceInfo.browser.name + " " + deviceInfo.browser.version}</td>
					</tr>
					<tr key="ishandheld">
						<th
							style={{
								width: "20%",
								textAlign: "left",
							}}
						>Is handheld</th>
						<td>{DeviceHelper.isHandHeld().toString()}</td>
					</tr>
					<tr key="isios">
						<th
							style={{
								width: "20%",
								textAlign: "left",
							}}
						>Is iOS</th>
						<td>{DeviceHelper.isIOS().toString()}</td>
					</tr>
					<tr key="isandroid">
						<th
							style={{
								width: "20%",
								textAlign: "left",
							}}
						>Is Android</th>
						<td>{DeviceHelper.isAndroid().toString()}</td>
					</tr>
				</tbody>
			</table>
		</Wrapper>
	)
}

export default StatsScreen
