import React, { useState, useEffect } from "react"
import {
	useParams,
	useNavigate,
} from "react-router-dom"
import CaseService from "../../services/CaseService"
import QuestionnaireManagementService from "../../services/QuestionnaireManagementService"
import ICase from "../../api/bo/models/case/ICase"
import ISimpleQuestionnaire from "../../api/bo/models/questionnaire/ISimpleQuestionnaire"
import AToolbar from "../../components/AToolbar"
import Wrapper from "../Wrapper"
import SendOutList from "./components/SendOutList"
import CreateSendOut from "./components/CreateSendOut"
import { IAButtonProps } from "../../components/AButton"
import ReceiverTypeService from "../../services/ReceiverTypeService"
import IReceiverType from "../../api/bo/models/receivertype/IReceiverType"
import ManageCase from "./components/ManageCase"
import SaveStatus from "../../components/form/SaveStatus"
import { IATabProps } from "../../components/ATab"
import { TabContext, TabPanel } from "@mui/lab"
import ATabPanel from "../../components/ATabPanel"
import { SendOutScreenTab } from "./SendOutScreen"

export enum CaseScreenTab {
	Info = "info",
	Sendouts = "sendouts",
}

const CaseScreen = () => {
	const navigate = useNavigate()

	const { caseId } = useParams<"caseId">()

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [isInitialized, setIsInitialized] = useState<boolean>(false)
	const [saveState, setSaveState] = useState<SaveStatus>(SaveStatus.Saved)

	const [isCaseFormValid, setIsCaseFormValid] = useState<boolean>(false)

	const getActiveTab = () => {
		const fragment = window.location.hash.replace("#", "")

		return fragment ? fragment as CaseScreenTab : CaseScreenTab.Info
	}

	const [activeTab, setActiveTab] = useState<CaseScreenTab>(getActiveTab())

	const [theCase, setTheCase] = useState<ICase | undefined>(undefined)
	const [questionnaires, setQuestionnaires] = useState<ISimpleQuestionnaire[]>([])
	const [receiverTypes, setReceiverTypes] = useState<IReceiverType[]>([])
	const [isCreateSendOutDialogOpen, setIsCreateSendOutDialogOpen] = useState<boolean>(false)

	// const [draft, setDraft] = useState<ICaseRequest | undefined>(undefined)
	const [identifier, setIdentifier] = useState<string>("")
	const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>(undefined)

	useEffect(() => {
		navigate("#" + activeTab)
	}, [activeTab])

	useEffect(() => {
		refresh()
	}, [])

	useEffect(() => {
		if (theCase === undefined) {
			return
		}

		if (identifier !== theCase.identifier
			// date of birth in response is null, in local model undefined
			// find a way to ascertain it's undefined in response
			// make soft check in the meantime
			|| dateOfBirth != theCase.dateOfBirth) {
			setSaveState(SaveStatus.HasChanges)
		}
	}, [identifier, dateOfBirth])

	const refresh = () => {
		if (caseId === undefined) {
			return
		}

		setIsLoading(true)

		CaseService.get(caseId).then(c => {
			const caseResp = c as ICase;
			setTheCase(caseResp)

			if (!isInitialized) {
				setIdentifier(caseResp.identifier)
				setDateOfBirth(caseResp.dateOfBirth)
				setIsInitialized(true)
			}

			setIsLoading(false)
		})
	}

	const save = () => {
		if (caseId === undefined) {
			return
		}

		setIsLoading(true)
		setSaveState(SaveStatus.Saving)

		CaseService.update(caseId, identifier, dateOfBirth).then(c => {
			setSaveState(SaveStatus.Saved)
			refresh()
		})
		.catch((e) => {
			setIsLoading(false)
		})
	}

	const openCreateSendOutDialog = () => {
		setIsLoading(true)

		Promise.all([
			QuestionnaireManagementService.getAll(),
			ReceiverTypeService.getAll(),
		]).then(values => {
			const questionnairesResponse = values[0]
			const receiverTypesResponse = values[1]

			if (!questionnairesResponse || !receiverTypesResponse) {
				return;
			}

			setQuestionnaires(questionnairesResponse)
			setReceiverTypes(receiverTypesResponse)

			setIsLoading(false)
			setIsCreateSendOutDialogOpen(true)
		});
	}

	const toolbarTabs: Array<IATabProps> = [
		{
			label: "info",
			value: CaseScreenTab.Info,
		},
		{
			label: "utskick",
			value: CaseScreenTab.Sendouts,
		},
	]

	const toolbarButtons: Array<IAButtonProps> = [
		{
			label: saveState === SaveStatus.Saved
				? "allt sparat"
				: saveState === SaveStatus.Saving
					? "sparar ..."
					: isCaseFormValid
						? "spara ändringar"
						: "fixa felen",
			action: save,
			type: "save",
			disabled: saveState !== SaveStatus.HasChanges || !isCaseFormValid,
			hidden: activeTab !== CaseScreenTab.Info,
		},
		{
			label: "utskick",
			action: openCreateSendOutDialog,
			type: "add",
		},
	]
	
	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			// showBack
			breadcrumbs={[
				{
					label: "start",
					path: "/",
				},
				{
					label: "ärenden",
					path: "/cases",
				},
				{
					label: theCase !== undefined
						? theCase.identifier
							+ (theCase.dateOfBirth
								? " " + CaseService.getFormattedDob(theCase.dateOfBirth)
								: ""
							)
						: "...",
				},
			]}
		>
			<AToolbar
				tabs={toolbarTabs}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				buttons={toolbarButtons}
			/>
			<TabContext
				value={activeTab}
			>
				<ATabPanel
					value={CaseScreenTab.Info}
				>
					<ManageCase
						identifier={identifier}
						dateOfBirth={dateOfBirth}
						setIdentifier={setIdentifier}
						setDateOfBirth={(v) => { setDateOfBirth(v) }}
						setIsValid={setIsCaseFormValid}
					/>
				</ATabPanel>
				<ATabPanel
					value={CaseScreenTab.Sendouts}
				>
					<SendOutList
						sendOuts={theCase ? theCase.sendOuts : []}
						edit={(key) => {
							navigate("/case/" + caseId + "/answers/" + key, {
								replace: false,
							})
						}}
						viewAnswers={(key) => {
							navigate("/case/" + caseId + "/answers/" + key + "#" + SendOutScreenTab.Answers, {
								replace: false,
							})
						}}
						setIsLoading={setIsLoading}
						refresh={refresh}
					/>
				</ATabPanel>
			</TabContext>
		{
			caseId !== undefined
			&& <CreateSendOut
				caseId={caseId}
				questionnaires={questionnaires}
				receiverTypes={receiverTypes}
				showDialog={isCreateSendOutDialogOpen}
				onCancel={() => {
					setIsCreateSendOutDialogOpen(false)
				}}
				onCreated={(key: string) => {
					refresh()
					setIsCreateSendOutDialogOpen(false)
				}}
			/>
		}
		</Wrapper>
	)
}

export default CaseScreen
