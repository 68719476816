import React, { ChangeEvent } from "react"
import {
	FormGroup
} from "@mui/material"
import Typer from "../../../components/form/Typer"

interface FormBaseProps {
	identifier: string
	displayName: string
	displayDescription: string
	setIdentifier: (identifier: string) => void
	setDisplayName: (displayName: string) => void
	setDisplayDescription: (displayDescription: string) => void
	setFormValid: (isValid: boolean) => void
	disabled?: boolean
}

interface FormBaseState {
	identifierValid?: boolean
	displayNameValid?: boolean
	displayDescriptionValid?: boolean
}

class FormBase extends React.Component<FormBaseProps, FormBaseState> {
	constructor(props: FormBaseProps) {
		super(props)

		this.state = {
		}
	}

	componentDidUpdate(prevProps: FormBaseProps) {
		this.validate()
	}

	validate = () => {
		const {
			identifierValid,
			displayNameValid,
			displayDescriptionValid,
		} = this.state
		const {
			setFormValid,
		} = this.props

		if (!identifierValid
			|| displayNameValid === false
			|| displayDescriptionValid === false) {
			setFormValid(false)
		}
		else {
			setFormValid(true)
		}
	}

	render() {
		const {
			identifier,
			displayName,
			displayDescription,
			setIdentifier,
			setDisplayName,
			setDisplayDescription,
			disabled,
		} = this.props
	
		return (
			<FormGroup>
				<Typer
					label="ID"
					value={identifier}
					required
					min={2}
					max={40}
					onChange={setIdentifier}
					setIsValid={(isValid: boolean) => {
						this.setState({
							identifierValid: isValid,
						})
					}}
					disabled={disabled}
				/>
				<Typer
					label="Namn"
					value={displayName}
					required
					min={2}
					max={40}
					onChange={setDisplayName}
					setIsValid={(isValid: boolean) => {
						this.setState({
							displayNameValid: isValid,
						})
					}}
					description="Visas för mottagaren."
					disabled={disabled}
				/>
				<Typer
					label="Beskrivning"
					value={displayDescription}
					required
					multiline
					min={2}
					max={400}
					onChange={setDisplayDescription}
					setIsValid={(isValid: boolean) => {
						this.setState({
							displayDescriptionValid: isValid,
						})
					}}
					description="Visas för mottagaren."
					disabled={disabled}
				/>
			</FormGroup>
		)
	}
}

export default FormBase
