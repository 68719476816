import React, { useState, useEffect } from "react"
import {
	useNavigate,
} from "react-router-dom"
import {
	EditRounded,
	AddRounded,
} from "@mui/icons-material"
import AToolbar from "../../components/AToolbar"

import Wrapper from "../Wrapper"
import UserList from "./components/UserList"
import CreateUser from "./components/CreateUser"
import IUser from "../../api/bo/models/user/IUser"
import UserService from "../../services/UserService"
import { IAButtonProps } from "../../components/AButton"

const UserOverviewScreen = () => {
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState<boolean>(true)

	const [users, setUsers] = useState<Array<IUser>>([])
	const [selectedUsers, setSelectedUsers] = useState<Array<IUser>>([])
	const [isCreateUserDialogOpen, setIsCreateUserDialogOpen] = useState<boolean>(false)

	useEffect(() => {
		refresh()
	}, [])

	function refresh() {
		setIsLoading(true)

		UserService.getAll().then(users => {
			if (Array.isArray(users)) {
				setUsers(users)
				setIsLoading(false)
			}
		})
	}

	function goToSelectedUser() {
		if (selectedUsers.length !== 1) {
			return
		}

		navigate("/user/" + selectedUsers[0].userId, {
			replace: false,
		})
	}

	const toolbarButtons: Array<IAButtonProps> = [
		{
			label: "ny användare",
			action: () => {
				setIsCreateUserDialogOpen(true)
			},
			icon: <AddRounded />,
			type: "add",
		},
	]

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			breadcrumbs={[
				{
					label: "start",
					path: "/"
				},
				{
					label: "användare",
				}
			]}
		>
			<AToolbar
				buttons={toolbarButtons}
			/>
			<UserList
				users={users}
				edit={(userId) => {
					navigate("/user/" + userId, {
						replace: false,
					})
				}}
			/>
			<CreateUser
				showDialog={isCreateUserDialogOpen}
				onCancel={() => {
					setIsCreateUserDialogOpen(false)
				}}
				onCreated={() => {
					refresh()
					setIsCreateUserDialogOpen(false)
				}}
			/>
		</Wrapper>
	)
}

export default UserOverviewScreen
