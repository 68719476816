import React, { useState, useEffect } from "react"
import {
	useNavigate,
	useParams,
} from "react-router-dom"
import QuestionnaireManagementService from "../../../services/QuestionnaireManagementService"
import PartList from "./components/PartList"
import CalculationBase from "./components/CalculationBase"
import CalculationService from "../../../services/CalculationService"
import CreatePart from "./components/CreatePart"
import Wrapper from "../../Wrapper"
import ICalculation from "../../../api/bo/models/questionnaire/ICalculation"
import ICalculationPart from "../../../api/bo/models/questionnaire/ICalculationPart"
import IQuestionnaire from "../../../api/bo/models/questionnaire/IQuestionnaire"
import ReceiverTypeService from "../../../services/ReceiverTypeService"
import IReceiverType from "../../../api/bo/models/receivertype/IReceiverType"
import { IAButtonProps } from "../../../components/AButton"
import AToolbar from "../../../components/AToolbar"
import { IATabProps } from "../../../components/ATab"
import { TabContext } from "@mui/lab"
import ATabPanel from "../../../components/ATabPanel"

export enum CalculationScreenTab {
	Info = "info",
	Rules = "rules",
}

const CalculationScreen = () => {
	const navigate = useNavigate()

	const { questionnaireId } = useParams<"questionnaireId">()
	const { calculationId } = useParams<"calculationId">()

	const getActiveTab = () => {
		const fragment = window.location.hash.replace("#", "")

		return fragment ? fragment as CalculationScreenTab : CalculationScreenTab.Info
	}

	const [activeTab, setActiveTab] = useState<CalculationScreenTab>(getActiveTab())

	const [questionnaire, setQuestionnaire] = useState<IQuestionnaire>()
	const [receiverTypes, setReceiverTypes] = useState<IReceiverType[]>([])
	const [activePartIndex, setActivePartIndex] = useState<number>()
	const [calculation, setCalculation] = useState<ICalculation>()

	const [identifier, setIdentifier] = useState<string>("")

	const [updateTimer, setUpdateTimer] = useState<ReturnType<typeof setTimeout>>()
	
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [isSaving, setIsSaving] = useState<boolean>(false)

	const [isAddPartPopupOpen, setIsAddPartPopupOpen] = useState<boolean>(false)

	useEffect(() => {
		navigate("#" + activeTab)
	}, [activeTab])

	useEffect(() => {
		if (questionnaire !== undefined
			&& calculation !== undefined) {
			return
		}

		refresh()
	}, [])

	useEffect(() => {
		if (updateTimer !== undefined) {
			clearTimeout(updateTimer)
		}

		setUpdateTimer(setTimeout(() => {
			if (calculationId === undefined
				|| questionnaireId === undefined
				|| !identifier) {
				return
			}
			
			CalculationService.update(calculationId, questionnaireId, identifier)
				.then(refresh)
		}, 2000))
	}, [identifier])

	const refresh = () => {
		if (!questionnaireId
			|| !calculationId) {
			return
		}

		Promise.all([
			QuestionnaireManagementService.get(questionnaireId),
			CalculationService.getOne(calculationId),
			ReceiverTypeService.getAll(),
		]).then(values => {
			const questionnaireResponse = values[0]
			const calculationResponse = values[1]
			const receiverTypesResponse = values[2]

			setIsLoading(false)
			setIsSaving(false) // ugly impl, but should work well enough

			if (!questionnaireResponse || !calculationResponse) {
				return
			}

			setQuestionnaire(questionnaireResponse)
			setCalculation(calculationResponse)
			setReceiverTypes(receiverTypesResponse)
			setIdentifier(calculationResponse.identifier)
		})
	}

	const updatePart = (part: ICalculationPart) => {
		if (!calculationId) {
			return
		}

		if (!part.partId) {
			save(() => {
				CalculationService.createPart(
					calculationId,
					part.identifier,
					part.questionIds,
					part.method,
					part.cutOffs
				).then(refresh)
			})
		}
		else {
			save(() => {
				CalculationService.updatePart(
					part.partId!,
					calculationId,
					part.identifier,
					part.questionIds,
					part.method,
					part.cutOffs
				).then(refresh)
			})
		}
	}

	const deletePart = (partId: string) => {
		setIsLoading(true)
		
		save(() => {
			CalculationService.deletePart(partId).then(refresh)
		})
	}

	const save = (callback: () => void) => {
		setIsSaving(true)

		callback()
	}

	const toolbarTabs: Array<IATabProps> = [
		{
			label: "info",
			value: CalculationScreenTab.Info,
		},
		{
			label: "regler",
			value: CalculationScreenTab.Rules,
		},
	]

	const toolbarButtons: IAButtonProps[] = [
		{
			label: isSaving ? "sparar ..." : "allt är sparat",
			action: () => {},
			disabled: true,
			type: "save",
		},
		{
			label: "regel",
			action: () => {
				setIsAddPartPopupOpen(true)
			},
			hidden: activeTab !== CalculationScreenTab.Rules,
			type: "add",
		},
	]

	const breadcrumbs: { label: string, path: string | undefined }[] = [
		{
			label: "start",
			path: "/",
		},
		{
			label: "formulär",
			path: "/questionnaires",
		},
		{
			label: !questionnaire
				? "..."
				: questionnaire.identifier,
			path: !questionnaire
				? undefined
				: "/questionnaire/" + questionnaire.questionnaireId,
		},
		{
			label: "beräkningar",
			path: !questionnaire
				? undefined
				: "/questionnaire/"
					+ questionnaire.questionnaireId
					+ "/calculations/",
		},
		{
			label: !calculation
				? "..."
				: calculation.identifier,
			path: undefined,
				// !questionnaire || !calculation
				// ? undefined
				// : "/questionnaire/"
				// 	+ questionnaire.questionnaireId
				// 	+ "/calculations/"
				// 	+ (!calculation.calculationId
				// 		? ""
				// 		: calculation.calculationId),
		}
	]

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			breadcrumbs={breadcrumbs}
		>
			<AToolbar
				tabs={toolbarTabs}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				buttons={toolbarButtons}
			/>
			<TabContext
				value={activeTab}
			>
				<ATabPanel
					value={CalculationScreenTab.Info}
				>
					<CalculationBase
						calculation={calculation}
						identifier={identifier}
						setIdentifier={setIdentifier}
					/>
				</ATabPanel>
				<ATabPanel
					value={CalculationScreenTab.Rules}
				>
					<PartList
						activePartIndex={activePartIndex}
						setActivePartIndex={setActivePartIndex}
						calculation={calculation}
						questionnaire={questionnaire}
						receiverTypes={receiverTypes}
						updatePart={updatePart}
						deletePart={deletePart}
					/>
				</ATabPanel>
			</TabContext>
			<CreatePart
				questionnaire={questionnaire}
				calculationId={calculationId}
				calculation={calculation}
				receiverTypes={receiverTypes}
				onCreated={(id: string) => {
					setIsAddPartPopupOpen(false)
					refresh()
				}}
				showDialog={isAddPartPopupOpen}
				onCancel={() => {
					setIsAddPartPopupOpen(false)
				}}
			/>
		</Wrapper>
	)
}

export default CalculationScreen
