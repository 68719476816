import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import React, { ChangeEvent } from "react"
import ICalculation from "../../../api/bo/models/questionnaire/ICalculation"
import Selecter from "../../../components/form/Selecter"
import ATable from "../../../components/ATable"
import CalculationService from "../../../services/CalculationService"

interface CalculationProps {
	calculations: ICalculation[]
	selectedCalculationId: string
	setCalculation: (calculation: ICalculation) => void
	tableData: {
		columns: GridColDef<any, any, any>[]
		rows: {
			[key: string]: string
		}[]
	}
}
interface CalculationState {
}

class Calculation extends React.Component<CalculationProps, CalculationState> {
	constructor(props: CalculationProps) {
		super(props)
	}

	fetchCalculation = (calculationId: string) => {
		const {
			setCalculation,
		} = this.props

		this.setState({
			selectedCalculationId: calculationId,
		}, () => {
			CalculationService.getOne(calculationId).then(calculation => {
				if (calculation === undefined) {
					return
				}

				setCalculation(calculation)
			})
		})
	}

	render() {
		const {
			calculations,
			selectedCalculationId,
			tableData,
		} = this.props

		return (
			<div
				style={{
					// flex: 1,
					display: "flex",
					flexDirection: "column",
					height: "100%",
					marginLeft: "6.5px",
				}}
			>
				<Selecter
					label="Vikta"
					value={selectedCalculationId}
					options={calculations.map(c => {
						return {
							value: c.calculationId!,
							label: c.identifier,
						}
					})}
					onSelect={(val) => {
						this.fetchCalculation(val as string)
					}}
				/>
				<ATable
					columns={tableData.columns}
					rows={tableData.rows}
					uniqueKey="partId"
				/>
			</div>
		)
	}
}

export default Calculation
