import React, { forwardRef } from "react"
import {
	DataGrid,
	DataGridProps,
	GridColDef,
	GridRowParams,
	GridRowSelectionModel,
	GridSortingInitialState,
	GridSortModel,
	GridValidRowModel,
	svSE,
} from "@mui/x-data-grid"
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity"
import moment from "moment"

// const MaterialTable = require("material-table").default

// const tableIcons: Icons = {
// 	Add: forwardRef((props: any, ref) => <AddBox {...props} ref={ref} />),
// 	Check: forwardRef((props: any, ref) => <Check {...props} ref={ref} />),
// 	Clear: forwardRef((props: any, ref) => <Clear {...props} ref={ref} />),
// 	Delete: forwardRef((props: any, ref) => <DeleteOutline {...props} ref={ref} />),
// 	DetailPanel: forwardRef((props: any, ref) => <ChevronRight {...props} ref={ref} />),
// 	Edit: forwardRef((props: any, ref) => <Edit {...props} ref={ref} />),
// 	Export: forwardRef((props: any, ref) => <SaveAlt {...props} ref={ref} />),
// 	Filter: forwardRef((props: any, ref) => <FilterList {...props} ref={ref} />),
// 	FirstPage: forwardRef((props: any, ref) => <FirstPage {...props} ref={ref} />),
// 	LastPage: forwardRef((props: any, ref) => <LastPage {...props} ref={ref} />),
// 	NextPage: forwardRef((props: any, ref) => <ChevronRight {...props} ref={ref} />),
// 	PreviousPage: forwardRef((props: any, ref) => <ChevronLeft {...props} ref={ref} />),
// 	ResetSearch: forwardRef((props: any, ref) => <Clear {...props} ref={ref} />),
// 	Search: forwardRef((props: any, ref) => <Search {...props} ref={ref} />),
// 	SortArrow: forwardRef((props: any, ref) => <ArrowDownward {...props} ref={ref} />),
// 	ThirdStateCheck: forwardRef((props: any, ref) => <Remove {...props} ref={ref} />),
// 	ViewColumn: forwardRef((props: any, ref) => <ViewColumn {...props} ref={ref} />)
// }

interface IATableProps<RowType extends GridValidRowModel> {
	columns: GridColDef<RowType>[]
	rows: RowType[]
	uniqueKey?: string
	onSelectionChange?: (selectedRows: any[]) => void
	onSelectedKeyChange?: (selectedKeys: any[]) => void
	selectedKeys?: GridRowSelectionModel
	enableSelection?: boolean
	enableMultiSelect?: boolean
	sortBy?: GridSortModel
	tableProps?: DataGridProps
}

interface IATableState {
	// selectedRows: any[]
}

class ATable<RowType extends GridValidRowModel> extends React.Component<IATableProps<RowType>, IATableState> {
	constructor(props: IATableProps<RowType>) {
		super(props)

		this.state = {
			// selectedRows: [],
		}
	}

	onSelectionChange = (selectedRowKeys: GridRowSelectionModel): void => {
		const {
			rows,
			uniqueKey,
			selectedKeys,
			enableMultiSelect,
			onSelectionChange,
			onSelectedKeyChange,
		} = this.props

		if (uniqueKey === undefined
			|| selectedKeys === undefined) {
			return
		}

		if (!onSelectedKeyChange
			&& !onSelectionChange) {
			return
		}

		let newSelectedRowKeys = selectedRowKeys

		if (!enableMultiSelect && selectedRowKeys.length > 1) {
			const firstNewSelection = newSelectedRowKeys.find(s => !selectedKeys.some(k => k === s))

			newSelectedRowKeys = []

			if (firstNewSelection !== undefined) {
				newSelectedRowKeys = [firstNewSelection]
			}
		}

		const selectedRows = rows.filter(r =>
			newSelectedRowKeys.some(s =>
				s === (r as any)[uniqueKey]))
		
		if (onSelectionChange) {
			onSelectionChange(selectedRows)
		}

		if (onSelectedKeyChange) {
			onSelectedKeyChange(newSelectedRowKeys)
		}
	}

	rowKeyExtractor = (row: RowType): any => {
		const {
			uniqueKey,
		} = this.props

		if (uniqueKey === undefined) {
			return undefined
		}

		try {
			return (row as any)[uniqueKey]
		}
		catch (e) {
			return undefined
		}
	}

	applyValueFormatters = (): GridColDef<RowType>[] => {
		const {
			columns,
		} = this.props

		for (let i in columns) {
			const column = columns[i]
			const type = column.type

			if (column.valueFormatter != undefined) {
				continue
			}

			switch (type) {
				case "date":
				case "datetime":
					column.valueFormatter = (params) => {
						if (!params.value) {
							return params.value
						}

						// const date = moment.utc(params.value)
						// const now = moment()

						// let format = "D/M" // "MM-DD"

						// if (date.year() != now.year()) {
						// 	format += " -YY" // = "YY-" + format
						// }

						let format = "YYYY-MM-DD"

						if (type == "datetime") {
							format += " HH:mm"
						}

						return moment.utc(params.value).format(format)
					}

					column.type = "string"
					break
			}
		}

		return columns
	}

	render() {
		const {
			rows,
			selectedKeys,
			enableSelection,
			sortBy,
			tableProps,
			uniqueKey,
		} = this.props

		// const flexColumns = columns.map(c => { return { flex: 1, ...c } })
		
		return (
			// <AutoSizer>
			// 	{({ width, height }) => {
			// 		const pageSize = Math.floor((height - 192) / 48)
			// 		console.log(`Page Size: ${height}`)
		
			// 		return (
						// <div style={{ height: `${height}px`, width: `${width}px`, overflowY: 'auto' }}>
			<DataGrid
				columns={this.applyValueFormatters()}
				rows={rows}
				initialState={{
					sorting: {
						sortModel: sortBy,
					} as GridSortingInitialState,
				} as GridInitialStateCommunity}
				// icons={tableIcons}
				// columns={columns.map((c) => { return {...c} })} // ugly fix to handle freezing browser cause of bug in lib
				// data={rows}
				// style={{
				// 	flex: 1,
				// }}
				pageSizeOptions={[100]}
				isRowSelectable={(params: GridRowParams<RowType>) => {
					return !!enableSelection
						&& !(params.row as any)["disableSelection"]
				}}
				getRowId={this.rowKeyExtractor}
				localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
				// options={{
				// 	pageSize: rows.length,
				// 	selection: !disableSelection,
				// 	search: false,
				// 	showTitle: false,
				// 	toolbar: false,
				// 	paging: false,
				// 	headerStyle: {
				// 		position: "sticky",
				// 		top: "0",
				// 	},
				// 	maxBodyHeight: "400px",
				// 	...options,
				// }}
				// checkboxSelection={!disableSelection}
				rowSelectionModel={selectedKeys}
				onRowSelectionModelChange={this.onSelectionChange}
				{...tableProps}
			/>
		)
	}
}

export default ATable
