import React, { ReactNode } from "react"
import { ButtonGroup, Button, Tab, Tabs } from "@mui/material"
import AButton, { IAButtonProps } from "./AButton"
import ATab, { IATabProps } from "./ATab"

export interface IAToolbarFilter {
	label: string
	action: Function
	disabled?: boolean
	value?: boolean
}

interface IAToolbarProps {
	tabs?: IATabProps[]
	activeTab?: string
	setActiveTab?: (value: any) => void
	buttons?: IAButtonProps[]
	filters?: IAToolbarFilter[]
	size?: "small" | "medium" | "large"
	style?: React.CSSProperties
}

class AToolbar extends React.Component<IAToolbarProps> {
	constructor(props: IAToolbarProps) {
		super(props)
	}

	renderTabs = () => {
		const {
			tabs,
		} = this.props

		if (tabs === undefined || !tabs.length) {
			return <></>
		}

		return <Tabs
			value={this.props.activeTab}
			onChange={(e, value) => {
				if (!this.props.setActiveTab) {
					return
				}

				this.props.setActiveTab(value)
			}}
			style={{
				flex: "0 0 auto",
				display: "flex",
				flexDirection: "row",
				marginRight: "5px",
			}}
		>
		{
			tabs.map((x, i) => {
				return this.renderTab(x, i)
			})
		}
		</Tabs>
	}

	renderTab = (tab: IATabProps, index: number) => {
		return <ATab
			{...tab}
			action={value => {
				if (!this.props.setActiveTab) {
					return
				}

				this.props.setActiveTab(value)
			}} 
		/>
	}
	
	renderButtons = () => {
		const {
			buttons,
		} = this.props

		if (buttons === undefined || !buttons.length) {
			return (<></>)
		}

		let groupedButtons: { group?: string, buttons: IAButtonProps[] }[] = []
		
		for (let i in buttons) {
			const b = buttons[i]

			if (b.group === undefined) {
				groupedButtons.push({ group: undefined, buttons: [b] })
			}
			else {
				if (!groupedButtons.some((v, i) => {
					return v.group === b.group
				})) {
					groupedButtons.push({ group: b.group, buttons: [] })
				}

				for (let j in groupedButtons) {
					if (groupedButtons[j].group === b.group) {
						groupedButtons[j].buttons.push(b)
					}
				}
			}
		}

		return (
			<div
				style={{
					flex: 1,
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
			{
				groupedButtons.map((b, i) => {
					return this.renderButton(b.buttons, i)
				})
			}
			</div>
		)
	}

	renderButton = (buttons: IAButtonProps[], index: number) => {
		const {
			size,
		} = this.props

		let hide = true

		for (let i in buttons) {
			if (!buttons[i].hidden) {
				hide = false
			}
		}

		if (hide) {
			return
		}

		return (<div
			style={{
				marginLeft: "5px",
			}}
			key={"toolbar-button-" + index}
		>
			{
				buttons.length > 1
				? <ButtonGroup
					variant={buttons[0].variant}
				>
				{
					buttons.map((b, i) => {
						return <AButton
							{...b}
							key={"toolbar-button-" + index + "" + i}
							size={size ?? "small"}
						/>
					})
				}
				</ButtonGroup>
				: <AButton
					{...buttons[0]}
					size={size ?? "small"}
				/>
			}
		</div>)
	}

	render() {
		const {
			style,
		} = this.props

		return (
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "stretch",
					marginBottom: "10px",
					...style,
				}}
			>
			{
				this.renderTabs()
			}
			{
				this.renderButtons()
			}
			</div>
		)
	}
}

export default AToolbar
