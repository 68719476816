import React from "react"
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import ICalculation from "../../../../api/bo/models/questionnaire/ICalculation"
import ATable from "../../../../components/ATable"
import { IconButton } from "@mui/material"
import { DeleteRounded, EditRounded } from "@mui/icons-material"
import CalculationService from "../../../../services/CalculationService"
import APopConfirm from "../../../../components/APopConfirm"

interface CalculationListProps {
	calculations: ICalculation[]
	edit: (id: string) => void
	refresh: () => void
	setIsLoading: (isLoading: boolean) => void
}

interface CalculationListState {
	columns: GridColDef<ICalculation>[]
	deleteId?: string
}

class CalculationList extends React.Component<CalculationListProps, CalculationListState> {
	constructor(props: CalculationListProps) {
		super(props)

		const columns = [
			{
				field: "edit",
				headerName: "",
				width: 0,
				sortable: false,
				renderCell: (params) => {
					return <IconButton
						size="small"
						onClick={() => { this.props.edit(params.row.calculationId as string) }}
						title="Öppna"
					>
						<EditRounded />
					</IconButton>
				},
			},
			{
				field: "identifier",
				headerName: "Namn",
				flex: 1,
			},
			{
				field: "delete",
				headerName: "",
				width: 0,
				sortable: false,
				renderCell: (params) => {
					return <IconButton
						size="small"
						onClick={() => {
							this.setState({
								deleteId: params.row.calculationId,
							})
						}}
						title="Radera"
					>
						<DeleteRounded />
					</IconButton>
				},
			},
		] as GridColDef<ICalculation>[]

		this.state = {
			columns,
		}
	}

	delete = () => {
		if (!this.state.deleteId) {
			return
		}

		this.props.setIsLoading(true)

		CalculationService.delete(this.state.deleteId).then(() => {
			this.props.refresh()
		})
		.finally(() => {
			this.setState({
				deleteId: undefined,
			})
		})
	}

	render() {
		const {
			calculations,
		} = this.props

		return (
			<>
				<ATable
					columns={this.state.columns}
					rows={calculations}
					uniqueKey="calculationId"
				/>
				<APopConfirm
					title="Radera beräkning"
					message="Är du säker på att du vill radera denna beräkning?"
					onCancel={() => {
						this.setState({
							deleteId: undefined,
						})
					}}
					onConfirm={this.delete}
					show={this.state.deleteId !== undefined}
				/>
			</>
		)
	}
}

export default CalculationList
