import React, { useState, useEffect } from "react"
import {
	useNavigate,
} from "react-router-dom"
import {
	FormGroup,
	Snackbar,
} from "@mui/material"
import AToolbar from "../../components/AToolbar"
import Wrapper from "../Wrapper"
import { IAButtonProps } from "../../components/AButton"
import SaveStatus from "../../components/form/SaveStatus"
import ChangePassword from "./components/ChangePassword"
import AccountService from "../../services/AccountService"
import IUserSettingsResponse from "../../api/bo/models/account/IUserSettingsResponse"
import Typer from "../../components/form/Typer"
import { IATabProps } from "../../components/ATab"
import { TabContext } from "@mui/lab"
import ATabPanel from "../../components/ATabPanel"

export enum AccountScreenTab {
	Settings = "settings",
}

const AccountScreen = () => {
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState<boolean>(true)
	// const [isInitialized, setIsInitialized] = useState<boolean>(false)
	const [saveState, setSaveState] = useState<SaveStatus>(SaveStatus.Saved)

	const getActiveTab = () => {
		const fragment = window.location.hash.replace("#", "")

		return fragment ? fragment as AccountScreenTab : AccountScreenTab.Settings
	}

	const [activeTab, setActiveTab] = useState<AccountScreenTab>(getActiveTab())

	const [settings, setSettings] = useState<IUserSettingsResponse | undefined>(undefined)
	const [draftSettings, setDraftSettings] = useState<IUserSettingsResponse | undefined>(undefined)

	const [unsubmittedSendOutsRetentionPeriodInDaysValid, setUnsubmittedSendOutsRetentionPeriodInDaysValid] = useState<boolean>(true)
	const [submittedSendOutsRetentionPeriodInDaysValid, setSubmittedSendOutsRetentionPeriodInDaysValid] = useState<boolean>(true)

	const [showChangePassword, setShowChangePassword] = useState<boolean>(false)
	const [showPasswordChangedNotice, setShowPasswordChangedNotice] = useState<boolean>(false)

	useEffect(() => {
		navigate("#" + activeTab)
	}, [activeTab])

	useEffect(() => {
		refresh()
	}, [])

	useEffect(() => {
		if (settings === undefined || draftSettings === undefined) {
			return
		}

		if (draftSettings.submittedSendOutsRetentionPeriodInDays !== settings.submittedSendOutsRetentionPeriodInDays
			|| draftSettings.unsubmittedSendOutsRetentionPeriodInDays !== settings.unsubmittedSendOutsRetentionPeriodInDays) {
			setSaveState(SaveStatus.HasChanges)
		}
		else {
			setSaveState(SaveStatus.Saved)
		}
	}, [draftSettings])

	const refresh = () => {
		setIsLoading(true)

		AccountService.getSettings().then(x => {
			const settings = x as IUserSettingsResponse
			
			setSettings(settings)
			setDraftSettings(settings)
		}).finally(() => {
			setIsLoading(false)
		})
	}

	const save = () => {
		if (!isValid() || draftSettings === undefined) {
			return
		}

		setIsLoading(true)
		setSaveState(SaveStatus.Saving)

		AccountService.saveSettings(draftSettings.unsubmittedSendOutsRetentionPeriodInDays,
			draftSettings.submittedSendOutsRetentionPeriodInDays).then(x => {
			setSaveState(SaveStatus.Saved)
			refresh()
		})
		.catch((e) => {
			setIsLoading(false)
		})
	}

	const isValid = () => {
		return unsubmittedSendOutsRetentionPeriodInDaysValid
			&& submittedSendOutsRetentionPeriodInDaysValid
	}

	const toolbarTabs: IATabProps[] = [
		{
			label: "inställningar",
			value: AccountScreenTab.Settings,
		},
	]

	const toolbarButtons: IAButtonProps[] = [
		{
			label: "byt lösenord",
			action: () => {
				setShowChangePassword(true)
			},
			type: "default",
		},
		{
			label: saveState === SaveStatus.Saved
				? "allt sparat"
				: saveState === SaveStatus.Saving
					? "sparar ..."
					: isValid()
						? "spara ändringar"
						: "fixa felen",
			action: save,
			type: "save",
			disabled: !isValid() || saveState !== SaveStatus.HasChanges,
		},
		{
			label: "logga ut",
			action: AccountService.signOut,
			type: "logout",
		},
	]

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			// showBack
			breadcrumbs={[
				{
					label: "start",
					path: "/"
				},
				{
					label: "ditt konto",
					path: undefined,
				},
			]}
		>
			<AToolbar
				tabs={toolbarTabs}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				buttons={toolbarButtons}
			/>
			<TabContext
				value={activeTab}
			>
				<ATabPanel
					value={AccountScreenTab.Settings}
				>
					<FormGroup>
						<Typer
							label="Radera obesvarade utskick efter (dagar)"
							value={draftSettings?.unsubmittedSendOutsRetentionPeriodInDays ?? ""}
							required
							type="number"
							min={7}
							max={1825}
							onChange={(x) => {
								try {
									if (draftSettings === undefined) {
										return
									}

									const unsubmittedSendOutsRetentionPeriodInDays = parseInt(x)

									setDraftSettings({...draftSettings, unsubmittedSendOutsRetentionPeriodInDays})
								}
								catch (e) {}
							}}
							setIsValid={setUnsubmittedSendOutsRetentionPeriodInDaysValid}
							description="Ange efter hur många dagar systemet automatiskt ska radera utskick som ej besvarats."
						/>
						<Typer
							label="Anonymisera besvarade utskick efter (dagar)"
							value={draftSettings?.submittedSendOutsRetentionPeriodInDays ?? ""}
							required
							type="number"
							min={21}
							max={1825}
							onChange={(x) => {
								try {
									if (draftSettings === undefined) {
										return
									}

									const submittedSendOutsRetentionPeriodInDays = parseInt(x)

									setDraftSettings({...draftSettings, submittedSendOutsRetentionPeriodInDays})
								}
								catch (e) {}
							}}
							setIsValid={setSubmittedSendOutsRetentionPeriodInDaysValid}
							description="Ange efter hur många dagar systemet automatiskt ska anonymisera inskickade formulär (all persondata kopplat till utskicket raderas)."
						/>
					</FormGroup>
				</ATabPanel>
			</TabContext>
			<ChangePassword
				show={showChangePassword}
				onCancel={() => {
					setShowChangePassword(false)
				}}
				onDone={() => {
					setShowChangePassword(false)
					setShowPasswordChangedNotice(true)
				}}
			/>
			<Snackbar
				open={showPasswordChangedNotice}
				autoHideDuration={6000}
				onClose={() => {
					setShowPasswordChangedNotice(false)
				}}
				message="Lösenord uppdaterat!"
			/>
		</Wrapper>
	)
}

export default AccountScreen
