import React, { ChangeEvent } from "react"
import {
	Button,
} from "@mui/material"
import DeviceHelper from "../../../helpers/DeviceHelper"
import IBankIdSignResponse from "../../../api/client/models/IBankIdSignResponse"
import moment from "moment"
import ClientQuestionnaireService from "../../../services/ClientQuestionnaireService"
import { QRCodeSVG } from "qrcode.react"

interface VerificationBankIdProps {
	bankIdResponse: IBankIdSignResponse
	qrAuthCode: string
	signStart: Date
	sameDevice: boolean
	cancel: () => void
	onSuccess: () => void
	onFail: () => void
}

interface VerificationBankIdState {
	qrAuthCode: string
	status: VerificationBankIdStatus
	collectTimeout?: ReturnType<typeof setTimeout>
	updateQrTimeout?: ReturnType<typeof setTimeout>
}

enum VerificationBankIdStatus {
	Pending,
	Success,
	Fail,
	Cancelled,
}

class VerificationBankId extends React.Component<VerificationBankIdProps, VerificationBankIdState> {
	constructor(props: VerificationBankIdProps) {
		super(props)

		const {
			qrAuthCode,
		} = this.props

		this.state = {
			qrAuthCode,
			status: VerificationBankIdStatus.Pending,
		}
	}

	componentDidMount(): void {
		this.setState({
			status: VerificationBankIdStatus.Pending,
		}, () => {
			this.updateQr()
			this.collectSignature()
		})
	}

	cancel = () => {
		clearTimeout(this.state.updateQrTimeout)
		clearTimeout(this.state.collectTimeout)

		this.setState({
			updateQrTimeout: undefined,
			collectTimeout: undefined,
			status: VerificationBankIdStatus.Cancelled,
		}, this.props.cancel)
	}

	generateLaunchUrl = () => {
		if (!this.props.bankIdResponse.autoStartToken.length) {
			// TODO: handle better
			return ""
		}
		
		let url = ""

		if (DeviceHelper.isAndroid()
			|| DeviceHelper.isIOS()
			|| DeviceHelper.isUnknownOsAndUnknownOrHandheldDevice()) {
			url = "https://app.bankid.com/"
		}
		else {
			url = "bankid:///"
		}

		url += "?autostarttoken="
			+ this.props.bankIdResponse.autoStartToken
			+ "&redirect="

		if (DeviceHelper.isIOS()) {
			url += encodeURIComponent(window.location.href + "#bankidreturn")
		}

		return url
	}

	updateQr = () => {
		if (this.state.status != VerificationBankIdStatus.Pending) {
			return
		}
		
		ClientQuestionnaireService
			.getQrCode(this.props.bankIdResponse.orderRef)
			.then((x) => {
				this.setState({
					qrAuthCode: x as string,
					updateQrTimeout: setTimeout(this.updateQr, 967),
				})
			}).catch(e => {
				// TODO
			})
	}

	collectSignature = () => {
		if (moment(new Date()).diff(this.props.signStart, "seconds") > 120) {
			this.setState({
				status: VerificationBankIdStatus.Fail,
			}, this.props.onFail)

			return
		}
		
		ClientQuestionnaireService
			.collectBankIdSignature(this.props.bankIdResponse.orderRef)
			.then((success) => {
				if (success) {
					this.setState({
						status: VerificationBankIdStatus.Success,
					}, this.props.onSuccess)
				}
				else {
					this.setState({
						status: VerificationBankIdStatus.Fail,
					}, this.props.onFail)
				}
			}).catch(e => {
				switch (e.status) {
					case 202:
						this.setState({
							collectTimeout: setTimeout(this.collectSignature, 2000),
						})
						break
					default:
						this.setState({
							status: VerificationBankIdStatus.Fail,
						}, this.props.onFail)
						break
				}
				// switch (e.data) {
				// 	case "BankIdPending":
				// 		setTimeout(() => { collectSignature(orderRef) }, 2000)
				// 		break
				// 	case "BankIdValidationFail":
				// 	default:
				// 		setActiveStep(6)
				// 		break
				// }
			})
	}

	render() {
		return <div
			style={{
				flex: 1,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<div
				style={{
					margin: "1.5rem auto 1rem",
					maxWidth: "400px",
				}}
			>
				<Button
					fullWidth
					size="large"
					color="primary"
					variant="contained"
					onClick={() => {
						window.open(this.generateLaunchUrl(), "_blank")
					}}>
					Öppna BankID på denna enhet
				</Button>
			</div>
			<span>Scanna QR-koden med din BankID-app.</span>
			{/* <span>Vill du öppna BankID på denna enhet istället så <a href={this.props.bankIdLaunchUrl} target="_blank">klicka här</a>.</span> */}
			<QRCodeSVG
				value={this.state.qrAuthCode ? this.state.qrAuthCode : ""}
				height="256"
				width="256"
				style={{
					margin: "auto",
					flex: 1,
					display: "flex",
					flexDirection: "column",
					// maxWidth: "66%",
					aspectRatio: 1,
					height: "70%",
				}}
			/>
			<div
				style={{
					margin: "25.5px auto 17px",
				}}
			>
				<Button
					fullWidth
					size="large"
					color="primary"
					variant="contained"
					onClick={this.cancel}
				>
					Avbryt
				</Button>
			</div>
		</div>
	}
}

export default VerificationBankId
