import React from "react"
import {
	Routes,
	Route,
} from "react-router-dom"
import { PAGES } from "../navigation/Pages";
import IPage from "../navigation/IPage";
import AccountService from "../services/AccountService"
import AuthenticateScreen from "./authenticate/AuthenticateScreen";

const Main = () => {
	const generateRoutes = (pages: IPage[]) => {
		var routes = new Array<JSX.Element>()

		for (let i in pages) {
			const page = pages[i]

			routes.push(generateRoute(page))

			if (page.sub != undefined) {
				routes = routes.concat(generateRoutes(page.sub))
			}
		}

		return routes
	};

	const generateRoute = (page: IPage) => {
		return <Route
			key={page.name}
			path={page.path}
			element={
				page.allowAnonymous
					? page.screen
					: <RequireAuth>
					{
						page.screen
					}
					</RequireAuth>
			}
		/>
	}

	return (
		<Routes>
		{
			generateRoutes(PAGES)
		}
			<Route
				path="/authenticate"
				element={
					<AuthenticateScreen />
				}
			/>
		</Routes>
	)
}

function RequireAuth({ children }: { children: JSX.Element }) {
	// const navigate = useNavigate()

	let path = window.location.href

	if (!AccountService.isAuthenticated()) {
		// navigate("/authenticate", {
		// 	replace: false,
		// 	state: { from: path },
		// })
		return <AuthenticateScreen
			returnUrl={path}
		/>
	}

	return children
}

export default Main
