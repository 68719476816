import React, { useState, useEffect } from "react"
import {
	useNavigate,
} from "react-router-dom"
import {
	FormatListBulletedRounded,
	AssignmentIndRounded,
	PeopleRounded,
	SettingsRounded,
} from "@mui/icons-material"
import AToolbar from "../../components/AToolbar"
import Wrapper from "../Wrapper"
import AccountService from "../../services/AccountService"
import { UserRole } from "../../api/bo/models/account/UserRole"
import { IAButtonProps } from "../../components/AButton"
import APopAlert from "../../components/APopAlert"

const StartScreen = () => {
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState<boolean>(true)

	useEffect(() => {
		setIsLoading(false)
	}, [])

	const toolbarButtons: Array<IAButtonProps> = [
		{
			label: "inställningar",
			action: () => {
				navigate("/settings")
			},
			icon: <SettingsRounded />,
		},
		{
			label: "formulär",
			action: () => {
				navigate("/questionnaires")
			},
			icon: <FormatListBulletedRounded />,
		},
		{
			label: "ärenden",
			action: () => {
				navigate("/cases")
			},
			icon: <AssignmentIndRounded />,
		},
	]

	const role = AccountService.getRole()

	if (role == UserRole.ADMIN) {
		toolbarButtons.push({
			label: "användare",
			action: () => {
				navigate("/users")
			},
			icon: <PeopleRounded />,
		})
	}

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			breadcrumbs={[
				{
					label: "Start",
				}
			]}
		>
			<AToolbar
				buttons={toolbarButtons}
				size="medium"
			/>
		</Wrapper>
	)
}

export default StartScreen
