import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import React from "react"
import ICase from "../../../api/bo/models/case/ICase"
import ATable from "../../../components/ATable"
import { IconButton } from "@mui/material"
import { DeleteRounded, EditRounded } from "@mui/icons-material"
import APopConfirm from "../../../components/APopConfirm"
import CaseService from "../../../services/CaseService"

interface CaseListProps {
	cases: ICase[]
	edit: (caseId: string) => void
	refresh: () => void
	setIsLoading: (isLoading: boolean) => void
}

interface CaseListState {
	columns: GridColDef<ICase>[]
	deleteId?: string
}

class CaseList extends React.Component<CaseListProps, CaseListState> {
	constructor(props: CaseListProps) {
		super(props)

		const columns = [
			{
				field: "edit",
				headerName: "",
				width: 0,
				sortable: false,
				renderCell: (params) => {
					return <IconButton
						size="small"
						onClick={() => { this.props.edit(params.row.caseId) }}
						title="Öppna"
					>
						<EditRounded />
					</IconButton>
				},
			},
			{
				field: "identifier",
				headerName: "Namn",
				flex: 0.4,
			},
			{
				field: "dateOfBirth",
				headerName: "Födelsedatum",
				type: "date",
				flex: 0.3,
			},
			{
				field: "createdAt",
				headerName: "Skapad",
				type: "date",
				flex: 0.3,
			},
			{
				field: "delete",
				headerName: "",
				width: 0,
				sortable: false,
				renderCell: (params) => {
					return <IconButton
						size="small"
						onClick={() => {
							this.setState({
								deleteId: params.row.caseId,
							})
						}}
						title="Radera"
					>
						<DeleteRounded />
					</IconButton>
				},
			},
		] as GridColDef<ICase>[]

		this.state = {
			columns,
		}
	}

	deleteSendout = () => {
		if (!this.state.deleteId) {
			return
		}

		this.props.setIsLoading(true)

		CaseService
			.delete(this.state.deleteId)
			.then(() => {
				this.props.refresh()
			})
			.finally(this.cancelDelete)
	}

	cancelDelete = () => {
		this.setState({
			deleteId: undefined,
		})
	}

	render() {
		return (
			<>
				<ATable
					columns={this.state.columns}
					rows={this.props.cases}
					uniqueKey="caseId"
					sortBy={[{ field: "createdAt", sort: "desc" }]}
				/>
				<APopConfirm
					title="Radera ärende"
					message="Är du säker på att du vill radera detta ärende?"
					onCancel={this.cancelDelete}
					onConfirm={this.deleteSendout}
					show={this.state.deleteId !== undefined}
				/>
			</>
		)
	}
}

export default CaseList
