import React from "react"
import QuestionType from "../../../api/bo/models/questionnaire/QuestionType"
import { FormControlLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import IClientAnswer from "../../../api/client/models/IClientAnswer"
import IClientSubmitAnswer from "../../../api/client/models/IClientSubmitAnswer"
import IClientQuestion from "../../../api/client/models/IClientQuestion"

interface SinglePageProps {
	questions: IClientQuestion[]
	showQuestionIdentifier?: boolean
	providedAnswers: IClientSubmitAnswer[]
	pickAnswer: (questionId: string, answerId: string) => void
	setFreeTextAnswer: (questionId: string, text: string) => void
}

interface SinglePageState {
}

class SinglePage extends React.Component<SinglePageProps, SinglePageState> {
	constructor(props: SinglePageProps) {
		super(props)
	}

	getProvidedAnswer = (questionId: string) => {
		return this.props.providedAnswers.find(a => a.questionId === questionId)
	}

	doesAllQuestionsHaveSameAnswers = () => {
		const {
			questions,
		} = this.props

		if (questions.length < 2) {
			return true
		}

		const baseQuestion = questions.find(x => x.answers.length)

		if (baseQuestion === undefined) {
			return true
		}

		const baseAnswers = baseQuestion.answers

		for (let i = 1; i < questions.length; i++) {
			const question = questions[i]

			if (question.type === QuestionType.FreeText) {
				continue
				// return false
			}

			const answers = this.getAnswersForQuestion(question)

			if (answers.length !== baseAnswers.length) {
				return false
			}

			for (let j = 0; j < answers.length; j++) {
				const answer = answers[j]
				const baseAnswer = baseAnswers.find(x => x.value == answer.value)

				if (baseAnswer === undefined) {
					return false
				}
				
				if (baseAnswer.text !== answer.text || baseAnswer.value !== answer.value) {
					return false
				}
			}
		}

		return true
	}

	getAnswersForQuestion = (question: IClientQuestion) : IClientAnswer[] => {
		let answers: IClientAnswer[] = []
		
		answers = question.answers
		
		// if (question.derivedAnswersId) {
		// 	const derivedFrom = this.props.questions.find(x => x.questionId === question.derivedAnswersId)

		// 	if (derivedFrom) {
		// 		answers = derivedFrom.answers
		// 	}
		// }

		return answers
	}

	showQuestionIdentifier = () => {
		return this.props.showQuestionIdentifier
			&& this.props.questions.find(x => x.identifier)
	}

	renderQuestion = (question: IClientQuestion) => {
		const answers = this.getAnswersForQuestion(question)
		const providedAnswer = this.getProvidedAnswer(question.questionId)

		return <TableRow
			key={"question-" + question.questionId}
		>
			<TableCell
				component="th"
				key="order"
			>
				<Typography
					variant="body1"
				>
				{
					"#" + question.order
				}
				</Typography>
			</TableCell>
		{
			this.showQuestionIdentifier()
			? <TableCell
				component="th"
				key="identifier"
			>
				<Typography
					variant="body1"
				>
				{
					question.identifier
				}
				</Typography>
			</TableCell>
			: undefined
		}
			<TableCell
				component="th"
				key="question"
			>
				<Typography
					variant="body1"
				>
				{
					question.text
				}
				</Typography>
			</TableCell>
			<TableCell
				key="answers"
			>
			{
				question.type === QuestionType.FreeText
				? <TextField
					name={"answer-" + question.order}
					onChange={(event) => {
						this.props.setFreeTextAnswer(question.questionId, event.target.value)
					}}
					multiline={true}
					maxRows={3}
					placeholder="Skriv ditt svar här ..."
					value={providedAnswer !== undefined
						? providedAnswer.text
						: ""}
					fullWidth
				/>
				: <RadioGroup
					aria-label={"answers-" + question.questionId}
					name={"answers-" + question.questionId}
					style={{
						flex: 1,
						flexDirection: "row",
						flexWrap: "nowrap",
					}}
				>
				{
					answers
						.map(answer => {
							return this.renderAnswer(question, answer, providedAnswer)
						})
				}
				</RadioGroup>
			}
			</TableCell>
		</TableRow>
	}

	renderAnswer = (question: IClientQuestion, answer: IClientAnswer, providedAnswer?: IClientSubmitAnswer) => {
		const label = !this.doesAllQuestionsHaveSameAnswers()
			? answer.text
			: ""
		
		return <FormControlLabel
			key={"answer-" + answer.order}
			value={answer.answerId}
			control={<Radio color="primary" />}
			label={label}
			checked={providedAnswer !== undefined && answer.answerId === providedAnswer.answerId}
			onChange={(event, checked) => {
				if (checked) {
					this.props.pickAnswer(question.questionId, answer.answerId)
				}
			}}
			style={{
				flex: 1 / question.answers.length,
			}}
		/>
	}

	render() {
		const {
			questions,
		} = this.props

		const firstQuestionWithAnswers = questions
			.find(q => q.answers)
		
		return <TableContainer
			style={{
				display: "flex",
				overflow: "initial",
			}}
		>
			<Table
				aria-label="questions"
				stickyHeader={this.doesAllQuestionsHaveSameAnswers() && firstQuestionWithAnswers !== undefined}
			>
			{
				this.doesAllQuestionsHaveSameAnswers() && firstQuestionWithAnswers !== undefined
				? <TableHead>
					<TableRow>
						<TableCell
							key="empty"
							colSpan={this.showQuestionIdentifier() ? 3 : 2}
						/>
						<TableCell
							key="answers"
						>
							<div
								style={{
									// flex: 1,
									flexDirection: "row",
									display: "flex",
									flexWrap: "nowrap",
								}}
							>
						{
							firstQuestionWithAnswers
								.answers
								.map(a => {
									return <div
										key={"answer-" + a.order}
										style={{
											flex: 1 / questions
												.find(q => q.answers)!
												.answers.length,
											paddingRight: "3pt",
										}}
									>
										<Typography
											variant="body1">
											{a.text}
										</Typography>
									</div>
							})
						}
							</div>
						</TableCell>
					</TableRow>
				</TableHead>
				: undefined
			}
				<TableBody>
				{
					questions.map((question) => {
						return this.renderQuestion(question)
					})
				}
				</TableBody>
			</Table>
		</TableContainer>
	}
}

export default SinglePage
