import React, { useState, useEffect } from "react"
import {
	useNavigate,
} from "react-router-dom"
import Wrapper from "../Wrapper"
import IReceiverType from "../../api/bo/models/receivertype/IReceiverType"
import ReceiverTypeApi from "../../api/bo/ReceiverTypeApi"
import ReceiverTypeService from "../../services/ReceiverTypeService"
import ReceiverTypeList from "./components/ReceiverTypeList"
import { IAButtonProps } from "../../components/AButton"
import { IATabProps } from "../../components/ATab"
import AToolbar from "../../components/AToolbar"
import CreateReceiverType from "./components/CreateReceiverType"
import { TabContext } from "@mui/lab"
import ATabPanel from "../../components/ATabPanel"

export enum SettingsScreenTab {
	Settings = "settings",
	ReceiverTypes = "receivertypes",
}

const SettingsScreen = (props: { section?: number }) => {
	const navigate = useNavigate()

	const [receiverTypes, setReceiverTypes] = useState<Array<IReceiverType>>([])
	const [activeReceiverTypeIndex, setActiveReceiverTypeIndex] = useState<number>()

	const getActiveTab = () => {
		const fragment = window.location.hash.replace("#", "")

		return fragment ? fragment as SettingsScreenTab : SettingsScreenTab.Settings
	}

	const [activeTab, setActiveTab] = useState<SettingsScreenTab>(getActiveTab())

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [isSaving, setIsSaving] = useState<boolean>(false)

	const [isAddReceiverTypeOpen, setIsAddReceiverTypeOpen] = useState<boolean>(false)

	useEffect(() => {
		navigate("#" + activeTab)
	}, [activeTab])

	useEffect(() => {
		refresh()
	}, [])

	const refresh = () => {
		setIsLoading(true)

		Promise.all([
			ReceiverTypeApi.getAll(),
		]).then(values => {
			const receiverTypesResponse = values[0]

			if (!receiverTypesResponse) {
				return
			}

			setReceiverTypes(receiverTypesResponse)
			setIsLoading(false)
			setIsSaving(false) // ugly impl, but should work well enough
		})
	}

	const updateReceiverType = (model: IReceiverType) => {
		setIsLoading(true)

		if (model.receiverTypeId == undefined) {
			save(() => {
				ReceiverTypeService
					.create(model.identifier)
					.then(refresh)
					.finally(() => {
						setIsLoading(false)
					})
			})
		}
		else {
			save(() => {
				ReceiverTypeService
					.update(model.receiverTypeId, model.identifier)
					.then(refresh)
					.finally(() => {
						setIsLoading(false)
					})
			})
		}
	}

	const deleteReceiverType = (id: string) => {
		setIsLoading(true)
		
		save(() => {
			ReceiverTypeService
				.delete(id)
				.then(refresh)
				.finally(() => {
					setIsLoading(false)
				})
		})
	}

	const save = (callback: () => void) => {
		setIsSaving(true)

		callback()
	}

	const toolbarTabs: IATabProps[] = [
		{
			label: "inställningar",
			value: SettingsScreenTab.Settings,
		},
		{
			label: "mottagartyper",
			value: SettingsScreenTab.ReceiverTypes,
		},
	]

	const toolbarButtons: IAButtonProps[] = [
		{
			label: "mottagartyp",
			action: () => {
				setIsAddReceiverTypeOpen(true)
			},
			hidden: activeTab !== SettingsScreenTab.ReceiverTypes,
			type: "add",
		},
		{
			label: "ta bort",
			action: () => {
				if (activeReceiverTypeIndex == undefined) {
					return
				}

				deleteReceiverType(receiverTypes[activeReceiverTypeIndex].receiverTypeId)
			},
			hidden: activeTab !== SettingsScreenTab.ReceiverTypes,
			disabled: activeReceiverTypeIndex == undefined,
			type: "delete",
		},
		{
			label: isSaving ? "sparar ..." : "allt sparat",
			action: () => {},
			disabled: true,
			type: "save",
		},
	]

	const breadcrumbs: { label: string, path: string | undefined }[] = [
		{
			label: "start",
			path: "/",
		},
		{
			label: "inställningar",
			path: undefined,
		},
	]

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			breadcrumbs={breadcrumbs}
		>
			<AToolbar
				tabs={toolbarTabs}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				buttons={toolbarButtons}
			/>
			<TabContext
				value={activeTab}
			>
				<ATabPanel
					value={SettingsScreenTab.Settings}
				>
				</ATabPanel>
				<ATabPanel
					value={SettingsScreenTab.ReceiverTypes}
				>
					<ReceiverTypeList
						items={receiverTypes}
						activeIndex={activeReceiverTypeIndex}
						setActiveIndex={setActiveReceiverTypeIndex}
						onUpdate={updateReceiverType}
						onDelete={deleteReceiverType}
					/>
				</ATabPanel>
			</TabContext>
			<CreateReceiverType
				onCreated={(id: string) => {
					setIsAddReceiverTypeOpen(false)
					refresh()
				}}
				showDialog={isAddReceiverTypeOpen}
				onCancel={() => {
					setIsAddReceiverTypeOpen(false)
				}}
			/>
		</Wrapper>
	)
}

export default SettingsScreen
