import React from "react"
import CaseService from "../../../services/CaseService"
import APopForm from "../../../components/APopForm"
import ManageCase from "../../case/components/ManageCase"

interface CreateCaseProps {
	onCreated: (id: string) => void
	onCancel: () => void
	isLoading: boolean
	setIsLoading: (isLoading: boolean) => void
	showDialog: boolean
}
interface CreateCaseState {
	identifier: string
	dateOfBirth?: Date
	isFormValid: boolean
}

class CreateCase extends React.Component<CreateCaseProps, CreateCaseState> {
	constructor(props: CreateCaseProps) {
		super(props)

		this.state = {
			identifier: "",
			isFormValid: false,
		}
	}

	createCase = () => {
		const {
			onCreated,
			setIsLoading,
		} = this.props
		const {
			identifier,
			dateOfBirth,
		} = this.state

		if (!this.state.isFormValid) {
			return
		}

		setIsLoading(true)

		CaseService.create(identifier, dateOfBirth).then(id => {
			if (id !== undefined) {
				this.clear(() => {
					setIsLoading(false)
					onCreated(id)
				})
			}
		})
	}

	clear = (callback?: () => void) => {
		this.setState({
			identifier: "",
			dateOfBirth: undefined,
			isFormValid: false,
		}, callback)
	}

	cancel = () => {
		this.clear(this.props.onCancel)
	}

	render() {
		const {
			identifier,
			dateOfBirth,
		} = this.state
		const {
			showDialog,
		} = this.props

		return <APopForm
			title="Skapa ärende"
			show={showDialog}
			onSubmit={this.createCase}
			onClose={this.cancel}
			submitDisabled={!this.state.isFormValid}
		>
			<ManageCase
				identifier={identifier}
				dateOfBirth={dateOfBirth}
				setIdentifier={(value: string) => {
					this.setState({
						identifier: value,
					})
				}}
				setDateOfBirth={(dateOfBirth?: Date) => {
					this.setState({
						dateOfBirth,
					})
				}}
				setIsValid={x => this.setState({
					isFormValid: x,
				})}
			/>
		</APopForm>
	}
}

export default CreateCase
