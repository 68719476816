import moment from "moment"
import CaseApi from "../api/bo/CaseApi"
import ICaseRequest from "../api/bo/models/case/ICaseRequest"

export default class CaseService {
	public static getAll() {
		return CaseApi.getAll()
	}
	
	public static get(id: string) {
		return CaseApi.getOne(id)
	}
	
	public static isIdentifierTaken(identifier: string) {
		return CaseApi.isIdentifierTaken(identifier)
	}
	
	public static create(identifier: string, dateOfBirth: Date | undefined) {
		return CaseApi.create({ identifier, dateOfBirth } as ICaseRequest)
	}
	
	public static update(id: string, identifier: string, dateOfBirth: Date | undefined) {
		return CaseApi.update(id, { identifier, dateOfBirth } as ICaseRequest)
	}
	
	public static close(id: string) {
		return CaseApi.close(id)
	}
	
	public static delete(id: string) {
		return CaseApi.delete(id)
	}

	public static getFormattedDob(dob?: Date) {
		if (dob === undefined) {
			return ""
		}

		return moment.utc(dob).format("YYYY-MM-DD")
	}
}
