import { UserRole } from "./models/account/UserRole"
import IUser from "./models/user/IUser"
import { RequestMethod } from "../RequestMethod"
import AdminBaseApi from "./AdminBaseApi"

export default class UserApi extends AdminBaseApi {
	protected static baseUri = "user"

	public static getAll() {
		return this.fetch<IUser[]>(undefined, RequestMethod.GET)
	}

	public static getOne(userId: string) {
		return this.fetch<IUser>(undefined, RequestMethod.GET, undefined, [ userId ])
	}

	public static create(username: string, password: string, role: UserRole = UserRole.STANDARD) {
		const load = {
			username,
			password,
			role,
		}

		return this.fetch<string>("create", RequestMethod.POST, load)
	}
}
