import React from "react"
import {
	FormGroup,
} from "@mui/material"
import Typer from "../../../components/form/Typer"
import IReceiverType from "../../../api/bo/models/receivertype/IReceiverType"
import Selecter from "../../../components/form/Selecter"

interface ManageSendOutProps {
	receiverTypes: IReceiverType[]
	description: string
	receiverTypeId?: string
	setDescription: (val: string) => void
	setReceiverTypeId: (val?: string) => void
}

interface ManageSendOutState {
	descriptionValid: boolean
	error?: string
}

export const EMPTY_SELECT_OPTION = "OTHER";

class ManageSendOut extends React.Component<ManageSendOutProps, ManageSendOutState> {
	constructor(props: ManageSendOutProps) {
		super(props)

		this.state = {
			descriptionValid: false,
		}
	}

	setReceiverType = (receiverTypeId: string) => {
		const {
			setReceiverTypeId,
		} = this.props

		setReceiverTypeId(receiverTypeId == EMPTY_SELECT_OPTION ? undefined : receiverTypeId)
	}

	render() {
		const {
			receiverTypes,
			description,
			receiverTypeId,
			setDescription,
		} = this.props
		const {
			error,
		} = this.state

		const receiverTypeOptions = receiverTypes.map(x => {
			return {
				value: x.receiverTypeId,
				label: x.identifier,
			};
		});

		receiverTypeOptions.push({
			value: EMPTY_SELECT_OPTION,
			label: "övrig",
		});

		return (
			<FormGroup>
				<Typer
					label="Mottagare"
					value={description}
					required
					min={4}
					max={100}
					setIsValid={(isValid: boolean) => {
						this.setState({
							descriptionValid: isValid,
						})
					}}
					onChange={setDescription}
				/>
				<Selecter
					value={receiverTypeId ?? EMPTY_SELECT_OPTION}
					label="Mottagartyp"
					required
					onSelect={(val) => {
						this.setReceiverType(val as string)
					}}
					options={receiverTypeOptions}
				/>
			</FormGroup>
		)
	}
}

export default ManageSendOut
