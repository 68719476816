import React, { useState, useEffect } from "react"
import {
	useNavigate,
} from "react-router-dom"
import AToolbar from "../../components/AToolbar"

import QuestionnaireManagementService from "../../services/QuestionnaireManagementService"
import ISimpleQuestionnaire from "../../api/bo/models/questionnaire/ISimpleQuestionnaire"

import QuestionnaireList from "./components/QuestionnaireList"
import Wrapper from "../Wrapper"
import { IAButtonProps } from "../../components/AButton"
import { QuestionnaireScreenTab } from "./QuestionnaireScreen"

const QuestionnaireOverviewScreen = () => {
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState<boolean>(true)

	const [questionnaires, setQuestionnaires] = useState<Array<ISimpleQuestionnaire>>([])

	useEffect(() => {
		refresh()
	}, [])
	
	const refresh = () => {
		setIsLoading(true)

		QuestionnaireManagementService.getAll().then(res => {
			setQuestionnaires(res as ISimpleQuestionnaire[])
			setIsLoading(false)
		})
	}

	const toolbarButtons: Array<IAButtonProps> = [
		{
			label: "formulär",
			action: () => {
				navigate("/questionnaire")
			},
			type: "add",
		},
	]

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			breadcrumbs={[
				{
					label: "start",
					path: "/"
				},
				{
					label: "formulär",
				}
			]}
		>
			<AToolbar
				buttons={toolbarButtons}
			/>
			<QuestionnaireList
				questionnaires={questionnaires.filter(q => { return !q.isHidden })}
				edit={(questionnaireId) => {
					navigate("/questionnaire/" + questionnaireId, {
						replace: false,
					})
				}}
				openCalculations={(questionnaireId) => {
					navigate("/questionnaire/" + questionnaireId + "/calculations", {
						replace: false,
					})
				}}
				preview={(questionnaireId) => {
					navigate("/questionnaire/" + questionnaireId + "#" + QuestionnaireScreenTab.Preview, {
						replace: false,
					})
				}}
				refresh={refresh}
				setIsLoading={setIsLoading}
			/>
		</Wrapper>
	)
}

export default QuestionnaireOverviewScreen
