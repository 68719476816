import React from "react"
import {
	FormGroup,
} from "@mui/material"
import Typer from "../../../components/form/Typer"
import moment from "moment"
import Dater from "../../../components/form/Dater"

interface ManageCaseProps {
	identifier: string
	dateOfBirth?: Date
	setIdentifier: (x: string) => void
	setDateOfBirth: (x?: Date) => void
	setIsValid: (x: boolean) => void
}

interface ManageCaseState {
	isIdentifierValid: boolean
	isDobValid: boolean
}

class ManageCase extends React.Component<ManageCaseProps, ManageCaseState> {
	constructor(props: ManageCaseProps) {
		super(props)

		this.state = {
			isIdentifierValid: true,
			isDobValid: true,
		}
	}

	componentDidMount(): void {
		this.validate()
	}

	setIdentifier = (identifier: string) => {
		const {
			setIdentifier,
		} = this.props

		setIdentifier(identifier)
	}

	setIdentifierValid = (isIdentifierValid: boolean) => {
		this.setState({
			isIdentifierValid,
		}, this.validate)
	}

	setDobValid = (isDobValid: boolean) => {
		this.setState({
			isDobValid,
		}, this.validate)
	}

	validate = () => {
		this.props.setIsValid(this.state.isIdentifierValid && this.state.isDobValid)
	}

	render() {
		const {
			identifier,
			dateOfBirth,
			setDateOfBirth,
		} = this.props

		return (
			<FormGroup>
				{/* <Collapse in={error.length !> 0}>
					<Alert severity="error">{error}</Alert>
				</Collapse> */}
				<Typer
					label="Namn"
					value={identifier}
					required
					min={2}
					max={200}
					onChange={this.setIdentifier}
					setIsValid={this.setIdentifierValid}
				/>
				<Typer
					type="date"
					label="Födelsedatum"
					value={dateOfBirth ? moment.utc(dateOfBirth).format("YYYY-MM-DD") : ""}
					max={new Date()}
					onChange={x => {
						setDateOfBirth(moment.utc(x).toDate())
					}}
					setIsValid={this.setDobValid}
				/>
				{/* <Dater
					label="Födelsedatum"
					value={dateOfBirth}
					max={new Date()}
					onChange={setDateOfBirth}
					setIsValid={this.setDobValid}
				/> */}
			</FormGroup>
		)
	}
}

export default ManageCase
