import {
	useLocation,
	useNavigate,
} from "react-router-dom"
import { PAGES } from "../navigation/Pages"
import IPage from "../navigation/IPage"
import {
	Link,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText
} from "@mui/material"
import AccountService from "../services/AccountService"

const AMenu = () => {
	const location = useLocation()
	const navigate = useNavigate()

	const urlPath = location.pathname.split("/")

	const getValidMenuItems = (pages: IPage[]) => {
		const filteredPages = pages.filter(x => !x.hideInMenu && !x.path.includes(":"))

		return filteredPages
	}

	const findActiveSubPage = (page: IPage) => {
		if (page.sub === undefined
			|| urlPath.length < 2) {
			return
		}

		for (let i = 0; i < page.sub.length; i++) {
			if (checkIfPageIsActive(page.sub[i])) {
				return page.sub[i]
			}
		}

		return
	}

	const checkIfPageIsActive = (page: IPage) => {
		// return page.path === location.pathname
		
		if (page.path === location.pathname) {
			return true
		}

		const pagePath = page.path.split("/")

		if (pagePath.length < 2) {
			return urlPath.length < 2
		}
		else if (urlPath.length < 2) {
			return false
		}

		return pagePath[1] === urlPath[1]
	}

	const generateListItem = (page: IPage, parents: IPage[], index: number) => {
		const activeSubPage = findActiveSubPage(page)
		const isPageActive = activeSubPage !== undefined || checkIfPageIsActive(page)

		return <ListItemButton
			LinkComponent={Link}
			href={page.path}
			key={"page-level" + parents.length.toString() + "-order" + index.toString()}
			onClick={(e) => {
				e.preventDefault()

				navigate(page.path)
			}}
			selected={isPageActive}
		>
		{
			page.icon
				? <ListItemIcon>{ page.icon }</ListItemIcon>
				: undefined
		}
			<ListItemText primary={page.name} />
		</ListItemButton>
	}

	const generateList = (pages: IPage[], parents: IPage[] = [], index: number = 0, traverse: boolean = true) => {
		const filteredPages = getValidMenuItems(pages)

		if (!filteredPages.length) {
			return <div key=""></div>
		}

		return <List
			key={"menu-level" + parents.length.toString() + "-order" + index.toString()}
		>
		{
			filteredPages.map((page, i) => {
				if (page.allowAnonymous !== undefined
					&& !page.allowAnonymous
					&& !AccountService.isAuthenticated()) {
					return
				}
				
				const userRole = AccountService.getRole()

				if (page.allowedRoles !== undefined
					&& !page.allowedRoles.some(role => role === userRole)) {
					return
				}
				
				if (page.blockedRoles !== undefined
					&& page.blockedRoles.some(role => role === userRole)) {
					return
				}

				const pageParents = parents.concat(page)

				if (traverse
					&& page.sub
					&& getValidMenuItems(page.sub).length) {
					return <>
					{
						generateListItem(page, pageParents, i)
					}
					{
						generateList(page.sub, pageParents, i)
					}
					</>
				}
				else {
					return generateListItem(page, pageParents, i)
				}
			})
		}
		</List>
	}

	return (
		generateList(PAGES, [], 0, false)
	)
}

export default AMenu
