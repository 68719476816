import APop, { IAPopProps } from "./APop"

export interface IAPopAlertProps extends IAPopProps {
	message: string
	onConfirm: () => void
}

class APopAlert<P extends IAPopAlertProps> extends APop<P> {
	constructor(props: P) {
		super(props)
	}
}

export default APopAlert
