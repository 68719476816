import React from "react"
import {
	FormGroup,
} from "@mui/material"
import Typer from "../../../components/form/Typer"

interface AuthFormProps {
	username: string
	password: string
	setUsername: (val: string) => void
	setPassword: (val: string) => void
}

interface AuthFormState {
	error?: string
}

class AuthForm extends React.Component<AuthFormProps, AuthFormState> {
	constructor(props: AuthFormProps) {
		super(props)

		this.state = {
		}
	}

	render() {
		const {
			username,
			password,
			setUsername,
			setPassword,
		} = this.props
		const {
			error,
		} = this.state

		return (
			<FormGroup>
				<Typer
					value={username}
					onChange={setUsername}
					label="Användarnamn"
				/>
				<Typer
					value={password}
					type="password"
					onChange={setPassword}
					label="Lösenord"
				/>
			</FormGroup>
		)
	}
}

export default AuthForm
