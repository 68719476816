import React from "react"
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import ATable from "../../../components/ATable"
import ISendOutListItem from "../../../api/bo/models/sendout/ISendOutListItem"
import { DeleteRounded, EditRounded, LinkRounded, VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material"
import { DialogContentText, IconButton, Snackbar, TextField } from "@mui/material"
import APopConfirm from "../../../components/APopConfirm"
import APopForm from "../../../components/APopForm"
import SendOutService from "../../../services/SendOutService"

interface SendOutListProps {
	sendOuts: ISendOutListItem[]
	edit: (key: string) => void
	viewAnswers: (key: string) => void
	refresh: () => void
	setIsLoading: (isLoading: boolean) => void
}

interface SendOutListState {
	columns: GridColDef<ISendOutListItem>[]
	deleteKey?: string
	copyLinkKey?: string
	copyLinkRole?: string
	showLinkCopiedSnackbar: boolean
}

class SendOutList extends React.Component<SendOutListProps, SendOutListState> {
	constructor(props: SendOutListProps) {
		super(props)

		const columns = [
			{
				field: "edit",
				headerName: "",
				width: 0,
				sortable: false,
				renderCell: (params) => {
					return <IconButton
						size="small"
						onClick={() => { this.props.edit(params.row.key) }}
						title="Öppna"
					>
						<EditRounded />
					</IconButton>
				},
			},
			{
				field: "copykey",
				headerName: "",
				width: 0,
				sortable: false,
				renderCell: (params) => {
					return <IconButton
						size="small"
						onClick={() => {
							this.setState({
								copyLinkKey: params.row.key,
								copyLinkRole: params.row.receiverTypeIdentifier ?? "övrig",
							})
						}}
						disabled={!!params.row.signDate}
						title="Kopiera länk"
					>
						<LinkRounded />
					</IconButton>
				},
			},
			{
				field: "viewanswers",
				headerName: "",
				width: 0,
				sortable: false,
				renderCell: (params) => {
					return <IconButton
						size="small"
						onClick={() => { this.props.viewAnswers(params.row.key) }}
						disabled={!params.row.signDate}
						title={!params.row.signDate ? "Obesvarad" : "Visa svar"}
					>
					{
						!params.row.signDate
							? <VisibilityOffRounded />
							: <VisibilityRounded />
					}
					</IconButton>
				},
			},
			{
				field: "description",
				headerName: "Mottagare",
				flex: 0.25,
			},
			{
				field: "receiverTypeIdentifier",
				headerName: "Mottagartyp",
				flex: 0.25,
				valueFormatter: (params) => {
					return params.value ?? "övrig"
				},
			},
			{
				field: "key",
				headerName: "Nyckel",
				flex: 0.25,
			},
			{
				field: "questionnaireIdentifier",
				headerName: "Formulär",
				flex: 0.25,
				// width: 100,
			},
			{
				field: "signDate",
				headerName: "Besvarad",
				type: "datetime",
				width: 150,
			},
			{
				field: "created",
				headerName: "Skapad",
				type: "date",
				width: 110,
			},
			{
				field: "delete",
				headerName: "",
				width: 0,
				sortable: false,
				renderCell: (params) => {
					return <IconButton
						size="small"
						onClick={() => {
							this.setState({
								deleteKey: params.row.key,
							})
						}}
						title="Radera"
					>
						<DeleteRounded />
					</IconButton>
				},
			},
		] as GridColDef<ISendOutListItem>[]

		this.state = {
			showLinkCopiedSnackbar: false,
			columns,
		}
	}

	generateDirectLink = () => {
		if (!this.state.copyLinkKey) {
			return ""
		}

		let role = this.state.copyLinkRole

		if (role) {
			// TODO: I mean, let's role with this for now
			role = role.toLowerCase()
				.replaceAll(/å|ä|â|á|à/g, "a")
				.replaceAll(/ö|ô/g, "o")
				.replaceAll(/ê|ë|é|è/g, "e")
				.replace(/[^a-zA-Z0-9-_]/g, "")
		}

		return window.location.protocol
			+ "//" + window.location.host
			+ "/q/" + this.state.copyLinkKey
			+ (role ? "/" + encodeURIComponent(role) : "")
	}

	copySendOutLinkToClipboard = () => {
		if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
			this.setState({
				showLinkCopiedSnackbar: true,
				copyLinkKey: undefined,
			})

			return navigator.clipboard.writeText(this.generateDirectLink())
		}
		else {
			this.setState({
				copyLinkKey: undefined,
			})
			// TODO: handle better
			return Promise.reject("The Clipboard API is not available.")
		}
	}

	deleteSendout = () => {
		const {
			setIsLoading,
			refresh,
		} = this.props
		const {
			deleteKey,
		} = this.state

		if (!deleteKey) {
			return
		}

		setIsLoading(true)

		SendOutService.delete(deleteKey).then(() => {
			refresh()
		})
		.finally(() => {
			this.setState({
				deleteKey: undefined,
			})
		})
	}

	render() {
		const {
			copyLinkKey,
			deleteKey,
			showLinkCopiedSnackbar,
		} = this.state

		return (
			<>
				<ATable
					columns={this.state.columns}
					rows={this.props.sendOuts}
					uniqueKey="key"
					sortBy={[{ field: "created", sort: "desc" }]}
				/>
				<Snackbar
					open={showLinkCopiedSnackbar}
					autoHideDuration={3000}
					onClose={() => {
						this.setState({
							showLinkCopiedSnackbar: false,
						})
					}}
					message="Länk kopierad till urklipp!"
				/>
				<APopForm
					title="Kopiera direktlänk"
					show={copyLinkKey !== undefined}
					onSubmit={this.copySendOutLinkToClipboard}
					onClose={() => {
						this.setState({
							copyLinkKey: undefined,
						})
					}}
					submitLabel="kopiera till urklipp"
				>
					<DialogContentText>
						Här kan du hämta direktlänk som kan skickas till den som ska besvara formuläret.
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						label="Länk"
						type="url"
						fullWidth
						variant="standard"
						value={this.generateDirectLink()}
						InputProps={{
							readOnly: true,
							autoFocus: true,
						}}
						onFocus={event => {
							event.target.select()
						}}
						// contentEditable="false"
					/>
				</APopForm>
				<APopConfirm
					title="Radera utskick"
					message="Är du säker på att du vill radera detta utskick?"
					onCancel={() => {
						this.setState({
							deleteKey: undefined,
						})
					}}
					onConfirm={this.deleteSendout}
					show={deleteKey !== undefined}
				/>
			</>
		)
	}
}

export default SendOutList
