import React from "react"
import CalculationMethod from "../../../../api/bo/models/questionnaire/CalculationMethod"
import ICalculation from "../../../../api/bo/models/questionnaire/ICalculation"
import ICalculationPart from "../../../../api/bo/models/questionnaire/ICalculationPart"
import IQuestionnaire from "../../../../api/bo/models/questionnaire/IQuestionnaire"
import CalculationService from "../../../../services/CalculationService"
import ManagePart from "./ManagePart"
import IReceiverType from "../../../../api/bo/models/receivertype/IReceiverType"
import APopForm from "../../../../components/APopForm"

interface CreatePartProps {
	questionnaire?: IQuestionnaire
	receiverTypes: IReceiverType[]
	onCreated: (id: string) => void
	onCancel: () => void
	showDialog: boolean
	calculationId?: string
	calculation?: ICalculation
}
interface CreatePartState {
	part: ICalculationPart
	error: string
}

class CreatePart extends React.Component<CreatePartProps, CreatePartState> {
	constructor(props: CreatePartProps) {
		super(props)

		this.state = {
			part: this.generateCleanPart(),
			error: "",
		}
	}

	create = () => {
		const {
			part,
		} = this.state
		const {
			onCreated,
			calculationId,
		} = this.props

		if (!part.identifier.length) {
			this.setState({
				error: "Vänligen, ange Namn för att skapa delberäkning",
			})

			return
		}

		if (!calculationId) {
			return
		}

		CalculationService.createPart(
			calculationId,
			part.identifier,
			part.questionIds,
			part.method,
			part.cutOffs,
		).then(id => {
			if (id != undefined) {
				this.clear()

				onCreated(id)
			}
		})
	}

	clear = () => {
		this.setState({
			part: this.generateCleanPart(),
		})
	}

	generateCleanPart = () => {
		return {
			identifier: "",
			method: CalculationMethod.Average,
			questionIds: [],
			cutOffs: [],
		} as ICalculationPart
	}

	cancel = () => {
		const {
			onCancel,
		} = this.props

		this.clear()

		if (onCancel !== undefined) {
			onCancel()
		}
	}

	render() {
		const {
			part,
			error,
		} = this.state
		const {
			questionnaire,
			calculation,
			receiverTypes,
			showDialog,
		} = this.props

		return (
			<APopForm
				title="Skapa delberäkning"
				show={showDialog}
				onSubmit={this.create}
				submitDisabled={!part.identifier.length}
				onClose={this.cancel}
			>
				<ManagePart
					updatePart={(part) => {
						this.setState({
							part,
						})
					}}
					part={part}
					questionnaire={questionnaire}
					receiverTypes={receiverTypes}
				/>
			</APopForm>
		)
	}
}

export default CreatePart
