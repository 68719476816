import { useState, useEffect } from "react"
import {
	useParams,
} from "react-router-dom"
import ClientQuestionnaireService from "../../services/ClientQuestionnaireService"
import Questionnaire from "./components/Questionnaire"
import Verification from "./components/Verification"
import IClientQuestionnaire from "../../api/client/models/IClientQuestionnaire"
import IClientQuestion from "../../api/client/models/IClientQuestion"
import { FormLayout } from "./components/FormLayout"
import Wrapper from "../Wrapper"
import SignatureType from "../../api/client/models/SignatureType"
import { AnswerStep } from "./components/AnswerStep"
import { VerificationStep } from "./components/VerificationStep"
import { Button } from "@mui/material"
import IClientSubmitAnswer from "../../api/client/models/IClientSubmitAnswer"

const Iframe = require("react-iframe").default

const AnswerScreen = () => {
	const { key } = useParams<"key">()

	const [questionnaire, setQuestionnaire] = useState<IClientQuestionnaire | undefined>(undefined)
	const [providedAnswers, setProvidedAnswers] = useState<IClientSubmitAnswer[]>([])

	const [signatureType, setSignatureType] = useState<SignatureType | undefined>(undefined)

	const [activeStep, setActiveStep] = useState<AnswerStep>(AnswerStep.LoadingQuestionnaire)
	const [activeVerificationStep, setActiveVerificationStep] = useState<VerificationStep>(VerificationStep.Select)

	const [activeQuestion, setActiveQuestion] = useState<IClientQuestion | undefined>(undefined)

	useEffect(() => {
		if (key == undefined) {
			setActiveStep(AnswerStep.MissingKey)
		}
		else {
			ClientQuestionnaireService.get(key).then(res => {
				const q = res as IClientQuestionnaire

				setQuestionnaire(q)
				setSignatureType(q.signatureType)
				setActiveStep(AnswerStep.Intro)
			}).catch(e => {
				switch (e.data) {
					case "AlreadySubmitted":
						setActiveStep(AnswerStep.AlreadySigned)
						break
				}
			})
		}
	}, [])

	const handleSignSuccess = () => {
		setActiveStep(AnswerStep.Success)
	}

	const handleSignFail = (errorCode?: string) => {
		setActiveStep(AnswerStep.SignFailed)
	}

	function renderStep() {
		switch (activeStep) {
			case AnswerStep.Questionnaire:
				if (questionnaire == undefined) {
					break
				}

				return <Questionnaire
					questionnaire={questionnaire}
					providedAnswers={providedAnswers}
					setProvidedAnswers={setProvidedAnswers}
					setActiveQuestion={setActiveQuestion}
					onSubmit={() => {
						setActiveStep(AnswerStep.Signing)
					}}
					layout={FormLayout.Stepping}
				/>
			case AnswerStep.Signing:
				if (key == undefined) {
					break
				}

				return <Verification
					signatureType={questionnaire?.signatureType || signatureType}
					sendOutKey={key}
					answers={providedAnswers}
					activeVerificationStep={activeVerificationStep}
					setActiveVerificationStep={setActiveVerificationStep}
					onSuccess={handleSignSuccess}
					onFail={handleSignFail}
				/>
			case AnswerStep.Intro:
				return <div
					style={{
						margin: "25.5px auto 17px",
						maxWidth: "400px",
					}}
				>
					<Button
						fullWidth
						size="large"
						color="primary"
						variant="contained"
						onClick={() => {
							setActiveStep(AnswerStep.Questionnaire)
						}}
					>
						Starta
					</Button>
				</div>
			case AnswerStep.SignFailed:
				return <div
					style={{
						margin: "25.5px auto 17px",
						maxWidth: "400px",
					}}
				>
					<Button
						fullWidth
						size="large"
						color="primary"
						variant="contained"
						onClick={() => {
							setActiveStep(AnswerStep.Signing)
						}}
					>
						Försök igen
					</Button>
				</div>
			// case AnswerStep.LoadingQuestionnaire:
			// case AnswerStep.AlreadySigned:
			// case AnswerStep.MissingKey:
			// case AnswerStep.Success:
			default:
				return undefined
		}
	}

	// TODO: make nicer
	const getTitle = () => {
		switch (activeStep) {
			case AnswerStep.Intro:
				if (questionnaire != undefined) {
					return "Välkommen, du ska nu få svara på några frågor gällande " + questionnaire.target
				}
				break
			case AnswerStep.LoadingQuestionnaire:
				return "Vänligen vänta"
			case AnswerStep.Questionnaire:
				if (questionnaire != undefined) {
					return questionnaire.name
				}
				break
			case AnswerStep.Signing:
				switch (activeVerificationStep) {
					case VerificationStep.BankIdPending:
						return "Öppna BankID för att signera"
					case VerificationStep.ManualPending:
						return "Ange dina personuppgifter"
					case VerificationStep.Select:
					case VerificationStep.Done:
						return "Verifiera din identitet"
				}
			case AnswerStep.SignFailed:
			case AnswerStep.AlreadySigned:
			case AnswerStep.MissingKey:
				return "Ojdå"
			case AnswerStep.Success:
				return "Tack"
		}

		return "..."
	}

	const getSubTitle = () => {
		switch (activeStep) {
			case AnswerStep.Intro:
				if (questionnaire != undefined) {
					return "Du ska nu får svara på frågor för formulär " + questionnaire.name
						+ " (" + questionnaire.description + "). "
						+ "När du är redo för att svara på frågorna i formuläret så klicka på knappen nedan. "
						+ "När du har svarat på frågorna kommer du att få verifiera vem du är. "
						+ "Efter att du har skickat in dina svar så kommer du inte att kunna svara igen. "
						+ "Ladda inte om denna sida innan du har skickat in dina svar, då börjar det om från början."
				}
				break
			case AnswerStep.LoadingQuestionnaire:
				return "Formuläret laddas in..."
			case AnswerStep.Questionnaire:
				if (activeQuestion != undefined) {
					return activeQuestion.order + ". " + activeQuestion.text
				}
				break
			case AnswerStep.Signing:
				switch (activeVerificationStep) {
					case VerificationStep.BankIdPending:
						return undefined
					case VerificationStep.ManualPending:
						return "Ange dina personuppgifter. Det är viktigt att de är korrekta."
					case VerificationStep.Select:
					case VerificationStep.Done:
						return "För att säkerställa att det är rätt person som har svarat på frågorna "
							+ "så måste du verifiera vem du är innan svaren kan skickas in."
				}
			case AnswerStep.AlreadySigned:
				return "Det här formuläret har redan besvarats och kan inte besvaras än en gång."
			case AnswerStep.SignFailed:
			case AnswerStep.MissingKey:
				return "Någonting gick fel. Försök igen. Om felet upprepar sig så kontakta ansvarig."
			case AnswerStep.Success:
				return "Dina svar har skickats in. Vi rekommenderar att du nu stänger denna flik."
		}

		return "..."
	}

	let progress = 0
	
	if (questionnaire != undefined) {
		progress = providedAnswers.length / questionnaire.questions.length * 100
	}

	return (
		<Wrapper
			className="answer"
			title={getTitle()}
			subTitle={getSubTitle()}
			progress={progress}
			hideMenu
		>
		{
			renderStep()
		}
		</Wrapper>
	)
}

export default AnswerScreen
