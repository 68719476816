import React from "react"
import APopForm from "../../../components/APopForm"
import AccountService from "../../../services/AccountService"
import AuthForm from "./AuthForm"

interface AuthPopProps {
	onAuth: () => void
	onCancel: () => void
	setIsLoading?: (isLoading: boolean) => void
	show: boolean
}
interface AuthPopState {
	username: string
	password: string
}

class AuthPop extends React.Component<AuthPopProps, AuthPopState> {
	constructor(props: AuthPopProps) {
		super(props)

		this.state = {
			username: "",
			password: "",
			// error: "",
		}
	}

	auth = () => {
		const {
			onAuth,
			setIsLoading,
		} = this.props
		const {
			username,
			password,
		} = this.state

		setIsLoading && setIsLoading(true)

		AccountService.authenticate(username, password).then(res => {
			if (res) {
				onAuth()
			}
			
			setIsLoading && setIsLoading(false)
		})
	}

	cancel = () => {
		const {
			onCancel,
		} = this.props

		this.setState({
			username: "",
			password: "",
		}, onCancel)
	}

	render() {
		const {
			username,
			password,
			// error,
		} = this.state
		const {
			show,
		} = this.props

		return <APopForm
			title="Utloggad"
			show={show}
			onSubmit={this.auth}
			submitLabel="Logga in"
			submitDisabled={!username.length || !password.length}
			onClose={this.cancel}
		>
			<AuthForm
				username={username}
				password={password}
				setUsername={(value: string) => {
					this.setState({
						username: value,
					})
				}}
				setPassword={(password: string) => {
					this.setState({
						password,
					})
				}}
			/>
		</APopForm>
	}
}

export default AuthPop
