import React, { ChangeEvent } from "react"
import {
	Button,
} from "@mui/material"
import IClientDetails from "../../../api/models/IClientDetails"
import Typer from "../../../components/form/Typer"

interface ManualVerificationProps {
	hideChangeMethod: boolean
	cancel: () => void
	submit: (info: IClientDetails) => void
}

interface ManualVerificationState {
	personId: string
	personIdValid?: boolean
	firstName: string
	firstNameValid?: boolean
	lastName: string
	lastNameValid?: boolean
	phoneNumber?: string
	phoneNumberValid?: boolean
	emailAddress?: string
	emailAddressValid?: boolean
}

class ManualVerification extends React.Component<ManualVerificationProps, ManualVerificationState> {
	constructor(props: ManualVerificationProps) {
		super(props)

		this.state = {
			personId: "",
			firstName: "",
			lastName: "",
			phoneNumber: "",
			emailAddress: "",
			personIdValid: true,
			firstNameValid: true,
			lastNameValid: true,
			phoneNumberValid: true,
			emailAddressValid: true,
		}
	}

	submit = () => {
		const {
			submit,
		} = this.props
		const {
			personId,
			firstName,
			lastName,
			phoneNumber,
			emailAddress,
		} = this.state

		if (!this.isValid())
		{
			// TODO: handle
			return
		}

		const details = {
			personId,
			firstName,
			lastName,
			phoneNumber,
			emailAddress,
		} as IClientDetails

		submit(details)
	}

	isValid = () => {
		const {
			personIdValid,
			firstNameValid,
			lastNameValid,
			phoneNumberValid,
			emailAddressValid,
		} = this.state

		return personIdValid
			&& firstNameValid
			&& lastNameValid
			&& phoneNumberValid
			&& emailAddressValid
	}

	render() {
		const {
			personId,
			firstName,
			lastName,
			phoneNumber,
			emailAddress,
			personIdValid,
			firstNameValid,
			lastNameValid,
			phoneNumberValid,
			emailAddressValid,
		} = this.state
		const {
			cancel,
			hideChangeMethod,
		} = this.props

		return (
			<>
				<Typer
					label="Personnummer"
					value={personId}
					required
					onChange={(value: string) => {
						this.setState({
							personId: value as string,
						})
					}}
					description="12 siffror utan skiljetecken (ex. 196408233234), eller 10 siffror med skiljetecken (ex. 640823-3234)."
					regExValidation={/^(19|20)?(\d{6}[-+]\d{4})|(\d{12})$/}
					setIsValid={(isValid: boolean) => {
						this.setState({
							personIdValid: isValid,
						})
					}}
					placeholder="19**********"
				/>
				<Typer
					label="Förnamn"
					value={firstName}
					required
					onChange={(value: string) => {
						this.setState({
							firstName: value as string,
						})
					}}
					// regExValidation={/^([^\p{N}\p{S}\p{C}\p{P}]{1,50})$/gu}
					regExValidation={/^([\p{L} -]{1,50})$/gui}
					setIsValid={(isValid: boolean) => {
						this.setState({
							firstNameValid: isValid,
						})
					}}
				/>
				<Typer
					label="Efternamn"
					value={lastName}
					required
					onChange={(value: string) => {
						this.setState({
							lastName: value,
						})
					}}
					regExValidation={/^([\p{L} -]{1,50})$/gui}
					setIsValid={(isValid: boolean) => {
						this.setState({
							lastNameValid: isValid,
						})
					}}
				/>
				<Typer
					label="Epost"
					value={emailAddress}
					onChange={(value: string) => {
						this.setState({
							emailAddress: value,
						})
					}}
					regExValidation={/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i}
					setIsValid={(isValid: boolean) => {
						this.setState({
							emailAddressValid: isValid,
						})
					}}
				/>
				<Typer
					label="Telefon"
					value={phoneNumber}
					onChange={(value: string) => {
						this.setState({
							phoneNumber: value,
						})
					}}
					regExValidation={/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.\/0-9]*$/}
					setIsValid={(isValid: boolean) => {
						this.setState({
							phoneNumberValid: isValid,
						})
					}}
				/>
				<div>
					<div
						style={{
							margin: "25.5px auto 17px",
							maxWidth: "400px",
						}}
					>
						<Button
							fullWidth
							size="large"
							color="primary"
							variant="contained"
							onClick={this.submit}
							disabled={!this.isValid()}
						>
							Verifiera och skicka in
						</Button>
					</div>
				{
					!hideChangeMethod
					? <div
						style={{
							margin: "0 auto 25.5px",
							maxWidth: "400px",
						}}
					>
						<Button
							fullWidth
							size="large"
							color="primary"
							variant="text"
							onClick={cancel}
						>
							Välj annan metod
						</Button>
					</div>
					: undefined
				}
				</div>
			</>
		)
	}
}

export default ManualVerification
