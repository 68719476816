import { RequestMethod } from "../RequestMethod"
import AdminBaseApi from "./AdminBaseApi"
import ISendOut from "./models/sendout/ISendOut"
import ISendOutCreateRequest from "./models/sendout/ISendOutCreateRequest"
import ISendOutListItem from "./models/sendout/ISendOutListItem"
import ISendOutUpdateRequest from "./models/sendout/ISendOutUpdateRequest"

export default class SendOutApi extends AdminBaseApi {
	protected static baseUri = "questionnairesendouts"

	public static getAllForCase(caseId: string) {
		return this.fetch<ISendOutListItem[]>(undefined, RequestMethod.GET, undefined, [ caseId ])
	}

	public static getByKey(key: string) {
		return this.fetch<ISendOut>(undefined, RequestMethod.GET, undefined, [ key ])
	}

	public static create(data: ISendOutCreateRequest) {
		return this.fetch<string>(undefined, RequestMethod.POST, data)
	}

	public static update(key: string, data: ISendOutUpdateRequest) {
		return this.fetch(undefined, RequestMethod.PUT, data, [ key ])
	}

	public static delete(key: string) {
		return this.fetch(undefined, RequestMethod.DELETE, undefined, [ key ])
	}
}
