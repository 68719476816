import { useState, useEffect } from "react"
import {
	useNavigate,
	useParams,
} from "react-router-dom"
import SendOutService from "../../services/SendOutService"
import QuestionnaireManagementService from "../../services/QuestionnaireManagementService"
import AnswersList from "./components/AnswersList"

import CaseService from "../../services/CaseService"
import CalculationService from "../../services/CalculationService"
import Calculation from "./components/Calculation"
import ICase from "../../api/bo/models/case/ICase"
import IQuestionnaire from "../../api/bo/models/questionnaire/IQuestionnaire"
import ICalculation from "../../api/bo/models/questionnaire/ICalculation"
import ISendOut from "../../api/bo/models/sendout/ISendOut"
import Wrapper from "../Wrapper"
import IQuestion from "../../api/bo/models/questionnaire/IQuestion"
import IAnswer from "../../api/bo/models/questionnaire/IAnswer"
import AToolbar from "../../components/AToolbar"
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import CalculationMethod from "../../api/bo/models/questionnaire/CalculationMethod"
import SignatureType from "../../api/client/models/SignatureType"
import moment from "moment"
import QuestionType from "../../api/bo/models/questionnaire/QuestionType"
import { IAButtonProps } from "../../components/AButton"
import ISendOutAnswer from "../../api/bo/models/sendout/ISendOutAnswer"
import ManageSendOut from "./components/ManageSendOut"
import ReceiverTypeService from "../../services/ReceiverTypeService"
import IReceiverType from "../../api/bo/models/receivertype/IReceiverType"
import Typer from "../../components/form/Typer"
import SaveStatus from "../../components/form/SaveStatus"
import { Alert } from "@mui/material"
import ATabPanel from "../../components/ATabPanel"
import { TabContext } from "@mui/lab"
import { IATabProps } from "../../components/ATab"

export enum SendOutScreenTab {
	Info = "info",
	Answers = "answers",
	Calculate = "calculate",
}

const SendOutScreen = () => {
	const navigate = useNavigate()
	
	const { caseId } = useParams<"caseId">()
	const { sendOutId } = useParams<"sendOutId">()

	const getActiveTab = () => {
		const fragment = window.location.hash.replace("#", "")

		return fragment ? fragment as SendOutScreenTab : SendOutScreenTab.Info
	}

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [initialized, setInitialized] = useState<boolean>(false)
	const [activeTab, setActiveTab] = useState<SendOutScreenTab>(getActiveTab())
	const [saveState, setSaveState] = useState<SaveStatus>(SaveStatus.Saved)

	const [theCase, setTheCase] = useState<ICase | undefined>(undefined)
	const [sendOut, setSendOut] = useState<ISendOut | undefined>(undefined)
	const [questionnaire, setQuestionnaire] = useState<IQuestionnaire | undefined>(undefined)
	const [calculations, setCalculations] = useState<ICalculation[]>([])
	const [calculation, setCalculation] = useState<ICalculation | undefined>(undefined)
	const [fullQuestionAnswerData, setFullQuestionAnswerData] = useState<{
		questionId: string
		questionOrder: number
		questionText: string
		questionIdentifier?: string
		answerValue?: number
		answerText: string
	}[]>([])

	const [receiverTypes, setReceiverTypes] = useState<IReceiverType[]>([])
	const [description, setDescription] = useState<string>("")
	const [receiverTypeId, setReceiverTypeId] = useState<string | undefined>(undefined)

	useEffect(() => {
		navigate("#" + activeTab)
	}, [activeTab])

	// TODO: create form handler to automate save state handling
	useEffect(() => {
		if (sendOut === undefined) {
			return
		}

		if (description !== sendOut.description
			|| receiverTypeId != sendOut.receiverTypeId) {
			setSaveState(SaveStatus.HasChanges)
		}
	}, [description, receiverTypeId])

	useEffect(() => {
		initialize()
	}, [])

	useEffect(() => {
		const fullQuestionAnswerData = getFullQuestionAnswerData()

		setFullQuestionAnswerData(fullQuestionAnswerData)
	}, [initialized])

	const initialize = () => {
		if (caseId == undefined || sendOutId == undefined) {
			return
		}

		setIsLoading(true)

		Promise.all([
			CaseService.get(caseId),
			SendOutService.getByKey(sendOutId),
			ReceiverTypeService.getAll(),
		]).then(x => {
			const caseResponse = x[0] as ICase
			const sendoutResponse = x[1] as ISendOut
			const receiverTypesResponse = x[2] as IReceiverType[]

			setTheCase(caseResponse)
			setSendOut(sendoutResponse)
			setDescription(sendoutResponse.description)
			setReceiverTypeId(sendoutResponse.receiverTypeId)
			setReceiverTypes(receiverTypesResponse)

			Promise.all([
				QuestionnaireManagementService.get(sendoutResponse.questionnaireId),
				CalculationService.getAllForQuestionnaire(sendoutResponse.questionnaireId),
			]).then(y => {
				const questionnaireResponse = y[0] as IQuestionnaire
				const calculationsResponse = y[1] as ICalculation[]

				setQuestionnaire(questionnaireResponse)
				setCalculations(calculationsResponse)

				setInitialized(true)
				setIsLoading(false)
			})
		})
	}

	const refresh = () => {
		if (sendOutId == undefined) {
			return
		}

		setIsLoading(true)

		Promise.all([
			SendOutService.getByKey(sendOutId),
		]).then(x => {
			const sendoutResponse = x[0] as ISendOut
			
			setSendOut(sendoutResponse)
			setDescription(sendoutResponse.description)
			setReceiverTypeId(sendoutResponse.receiverTypeId)

			setIsLoading(false)
		})
	}

	const save = () => {
		if (sendOutId === undefined) {
			return
		}

		setIsLoading(true)
		setSaveState(SaveStatus.Saving)

		SendOutService.update(sendOutId, description, receiverTypeId).then(x => {
			setSaveState(SaveStatus.Saved)
			refresh()
		})
		.catch((e) => {
			setIsLoading(false)
		})
	}

	const breadcrumbs: { label: string, path?: string }[] = [
		{
			label: "start",
			path: "/"
		},
		{
			label: "ärenden",
			path: "/cases",
		},
		{
			label: theCase !== undefined ?
				theCase.identifier
					+ (theCase?.dateOfBirth !== undefined
						? ""
						: " " + CaseService.getFormattedDob(theCase.dateOfBirth))
				: "...",
			path: "/case/" + caseId,
		},
		{
			label: sendOut?.description ?? "...",
		}
	]

	const isNotSigned = !sendOut || !sendOut.signDate

	const getCaseLabel = () => {
		if (theCase) {
			return theCase.identifier
				+ (
					!theCase.dateOfBirth
						? ""
						: " (" + CaseService.getFormattedDob(theCase.dateOfBirth) + ")"
				)
		}

		return ""
	}

	const getReceiverTypeLabel = () => {
		if (sendOut && sendOut.receiverTypeIdentifier) {
			return sendOut.receiverTypeIdentifier.toUpperCase()
		}

		return "ÖVRIG"
	}

	const getSignatureLabel = () => {
		if (sendOut) {
			if (sendOut.signDate && !sendOut.clientDetails) {
				return "[ANONYMISERAD]"
			}
			else if (sendOut.clientDetails) {
				let signature = sendOut.clientDetails.firstName + " "
					+ sendOut.clientDetails.lastName + " "
					+ "(" + sendOut.clientDetails.personId + ") "
	
				switch (sendOut.signatureType) {
					case SignatureType.BankId:
						signature += "BANKID"
						break
					default:
						signature += "MANUELL"
						break
				}

				return signature
			}
		}

		return ""
	}

	const getBaseInfoRows = () => {
		return [
			{
				label: "Ärende",
				value: getCaseLabel(),
			},
			{
				label: "Formulär",
				value: questionnaire?.identifier ?? "",
			},
			{
				label: "Mottagartyp",
				value: getReceiverTypeLabel(),
			},
			{
				label: "Skickat till",
				value: sendOut?.description ?? "",
			},
			{
				label: "Signerat av",
				value: getSignatureLabel(),
			},
		]
	}

	const getFullQuestionAnswerData = () => {
		if (sendOut === undefined
			|| sendOut.answers === undefined
			|| questionnaire === undefined
			|| questionnaire.questions === undefined
		) {
			return []
		}

		let questionAnswers: {
			questionId: string,
			questionOrder: number,
			questionText: string,
			questionIdentifier?: string
			answerValue?: number,
			answerText: string,
		}[] = []

		sendOut.answers.forEach((sa: ISendOutAnswer) => {
			const question: IQuestion | undefined = questionnaire.questions.find((q: IQuestion) => q.questionId === sa.questionId)

			if (question === undefined) {
				return
			}

			let answerValue = undefined
			let answerText = "Error"

			if (question.type === QuestionType.FreeText) {
				if (sa.text === undefined) {
					return
				}

				answerText = sa.text
			}
			else if (sa.answerId !== undefined) {
				let answer: IAnswer | undefined = question.answers.find((a: IAnswer) => a.answerId === sa.answerId)
	
				let derivedFromQuestion: IQuestion | undefined = undefined
	
				if (question.derivedAnswersId) {
					derivedFromQuestion = questionnaire.questions.find((q: IQuestion) => q.questionId === question.derivedAnswersId)
					answer = derivedFromQuestion!.answers.find((a: IAnswer) => a.answerId == sa.answerId)
				}
	
				if (answer == undefined) {
					return
				}

				answerValue = answer.value
				answerText = answer.text
			}
			
			questionAnswers.push({
				questionId: question.questionId!,
				questionOrder: question.order,
				questionText: question.text,
				questionIdentifier: question.identifier,
				answerValue: answerValue,
				answerText: answerText,
			})
		})

		return questionAnswers.sort((a, b) => {
			return a.questionOrder - b.questionOrder
		})
	}

	const generateCalculationTable = () => {
		const columns: GridColDef<any>[] = []
		const rows: {[key: string]: string}[] = []

		if (calculation !== undefined && sendOut !== undefined) {
			columns.push({
				field: "part",
				headerName: "Del",
				flex: 0.5,
			})

			const cutoffExistForReceiverType = calculation.parts.some(x => x.cutOffs.some(y => y.receiverTypeId == sendOut.receiverTypeId))

			columns.push({
				field: "result",
				headerName: "Resultat",
				type: "numeric",
				flex: cutoffExistForReceiverType ? 0.25 : 0.5,
			})

			if (cutoffExistForReceiverType) {
				columns.push({
					field: "cutOff",
					headerName: "Gränsvärde",
					type: "numeric",
					flex: 0.25,
				})
			}

			for (let i in calculation.parts) {
				const part = calculation.parts[i]
				const row: {[key: string]: string} = {}

				row["part"] = part.identifier
				row["partId"] = part.partId || ""
				
				const partAnswers = fullQuestionAnswerData
					.filter(a => part.questionIds.some(q => q == a.questionId))
				const partAnswersValues = partAnswers
					.filter(a => a.answerValue != undefined)
					.map(a => a.answerValue!)
					.sort((a, b) => a - b)

				let sum: number = partAnswersValues.reduce((a, b) => a + b, 0)

				switch (part.method) {
					case CalculationMethod.Average:
						sum = sum / partAnswersValues.length
						break
					case CalculationMethod.Median:
						const mid = Math.floor(partAnswersValues.length / 2)
						
						sum = partAnswersValues.length % 2 !== 0
							? partAnswersValues[mid]
							: (partAnswersValues[mid - 1] + partAnswersValues[mid]) / 2
						break
					case CalculationMethod.Sum:
						break
				}

				row["result"] = sum.toFixed(3)

				const cutOff = part.cutOffs.find(x => x.receiverTypeId == sendOut.receiverTypeId)

				if (cutOff !== undefined) {
					row["cutOff"] = cutOff.amount.toFixed(3)
				}

				rows.push(row)
			}
		}

		return { columns, rows }
	}

	const toolbarTabs: Array<IATabProps> = [
		{
			label: "info",
			value: SendOutScreenTab.Info,
		},
		{
			label: "utskick",
			value: SendOutScreenTab.Answers,
			disabled: isNotSigned,
		},
		{
			label: "vikta",
			value: SendOutScreenTab.Calculate,
			disabled: isNotSigned,
		},
	]

	const toolbarButtons: Array<IAButtonProps> = [
		{
			label: saveState === SaveStatus.Saved
				? "allt sparat"
				: saveState === SaveStatus.Saving
					? "sparar ..."
					: "spara ändringar",
			action: save,
			type: "save",
			disabled: saveState !== SaveStatus.HasChanges,
			hidden: activeTab !== SendOutScreenTab.Info,
		},
		{
			label: "Exportera",
			action: () => {
				SendOutService.downloadPdf(
					generateCalculationTable(),
					getBaseInfoRows(),
					getFullQuestionAnswerData()
				)
			},
			type: "download",
			disabled: isNotSigned,
		},
		// {
		// 	label: "spara",
		// 	action: () => {
		// 		// handle
		// 	},
		// },
	]

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			breadcrumbs={breadcrumbs}
			contentType="data"
		>
			<AToolbar
				tabs={toolbarTabs}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				buttons={toolbarButtons}
			/>
			<TabContext
				value={activeTab}
			>
				<ATabPanel
					value={SendOutScreenTab.Info}
				>
				{
					sendOut && !sendOut.signDate
						? <Alert severity="info">Detta utskick är än ej besvarat.</Alert>
						: undefined
				}
					<ManageSendOut
						description={description}
						setDescription={setDescription}
						receiverTypeId={receiverTypeId}
						receiverTypes={receiverTypes}
						setReceiverTypeId={setReceiverTypeId}
					/>
					<Typer
						label="Ärende"
						value={getCaseLabel()}
						disabled={true}
					/>
					<Typer
						label="Signatur"
						value={getSignatureLabel()}
						disabled={true}
					/>
					<Typer
						label="Formulär"
						value={questionnaire?.identifier ?? ""}
						disabled={true}
					/>
				</ATabPanel>
				<ATabPanel
					value={SendOutScreenTab.Answers}
				>
					<AnswersList
						rows={fullQuestionAnswerData}
					/>
				</ATabPanel>
				<ATabPanel
					value={SendOutScreenTab.Calculate}
				>
					<Calculation
						calculations={calculations}
						selectedCalculationId={calculation?.calculationId || ""}
						setCalculation={setCalculation}
						tableData={generateCalculationTable()}
					/>
				</ATabPanel>
			</TabContext>
		</Wrapper>
	)
}

export default SendOutScreen
