import React, { ReactNode } from "react"
import { ButtonGroup, Button } from "@mui/material"
import {
	EditRounded,
	AddRounded,
	LockRounded,
	FunctionsRounded,
	DeleteForeverRounded,
	SaveRounded,
	DownloadRounded,
	CancelRounded,
	CloseRounded,
	CheckRounded,
	SendRounded,
	VisibilityRounded,
	LogoutRounded,
} from "@mui/icons-material"

export interface IAButtonProps {
	label: string
	action?: (e: React.MouseEvent) => void
	disabled?: boolean
	hidden?: boolean
	group?: any
	type?: "default"
		| "edit"
		| "submit"
		| "confirm"
		| "cancel"
		| "close"
		| "save"
		| "add"
		| "lock"
		| "download"
		| "delete"
		| "logout"
		| "view"
	variant?: "text"
		| "outlined"
		| "contained"
	active?: boolean
	icon?: ReactNode
	color?: "inherit"
		| "primary"
		| "secondary"
		| "success"
		| "error"
		| "info"
		| "warning"
	size?: "small"
		| "medium"
		| "large"
	style?: React.CSSProperties
}

class AButton extends React.Component<IAButtonProps> {
	constructor(props: IAButtonProps) {
		super(props)
	}

	render() {
		const {
			label,
			action,
			disabled,
			hidden,
			group,
			type,
			variant,
			active,
			icon,
			color,
			size,
			style,
		} = this.props

		if (hidden) {
			return
		}

		let finalVariant = variant ?? "contained"
		let finalColor = color ?? "primary"
		let finalIcon = icon

		switch (type) {
			case "save":
				finalColor = "warning"
				finalVariant = "text"
				finalIcon = <SaveRounded />
				break
			case "lock":
				finalColor = "warning"
				finalVariant = "text"
				finalIcon = <LockRounded />
				break
			case "delete":
				finalColor = "error"
				finalVariant = "text"
				finalIcon = <DeleteForeverRounded />
				// finalVariant = "outlined"
				break
			case "add":
				finalColor = "success"
				finalIcon = <AddRounded />
				break
			case "download":
				finalColor = "secondary"
				finalVariant = "text"
				finalIcon = <DownloadRounded />
				break
			
			case "default":
				break
			case "confirm":
				// finalColor = "info"
				finalIcon = <CheckRounded />
				break
			case "submit":
				finalIcon = <SendRounded />
				break
			case "edit":
				finalIcon = <EditRounded />
				break
			case "view":
				finalIcon = <VisibilityRounded />
				break
			case "close":
				// finalColor = "info"
				finalVariant = "text"
				finalIcon = <CloseRounded />
				break
			case "cancel":
				// finalColor = "warning"
				finalVariant = "text"
				finalIcon = <CancelRounded />
				break
			case "logout":
				finalColor = "error"
				finalVariant = "text"
				finalIcon = <LogoutRounded />
				break
		}

		if (disabled || active) {
			finalVariant = "text"
		}

		finalVariant = variant ?? finalVariant
		finalColor = color ?? finalColor
		finalIcon = icon ?? finalIcon

		return <Button
			disabled={active || disabled}
			type={type == "submit" ? "submit" : "button"}
			variant={finalVariant}
			size={size}
			color={finalColor}
			startIcon={finalIcon}
			onClick={e => {
				action && action(e)
			}}
			style={{...style}}
		>
			{label}
		</Button>
	}
}

export default AButton
