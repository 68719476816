import React from "react"
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import ISimpleQuestionnaire from "../../../api/bo/models/questionnaire/ISimpleQuestionnaire"
import ATable from "../../../components/ATable"
import { IconButton } from "@mui/material"
import { DeleteRounded, EditRounded, FunctionsRounded, LockOpenRounded, LockRounded, VisibilityRounded } from "@mui/icons-material"
import APopConfirm from "../../../components/APopConfirm"
import QuestionnaireManagementService from "../../../services/QuestionnaireManagementService"

interface QuestionnaireListProps {
	questionnaires: ISimpleQuestionnaire[]
	edit: (id: string) => void
	openCalculations: (id: string) => void
	preview: (id: string) => void
	refresh: () => void
	setIsLoading: (isLoading: boolean) => void
}

interface QuestionnaireListState {
	columns: GridColDef<ISimpleQuestionnaire>[]
	deleteId?: string
	lockId?: string
}

class QuestionnaireList extends React.Component<QuestionnaireListProps, QuestionnaireListState> {
	constructor(props: QuestionnaireListProps) {
		super(props)

		const columns = [
			{
				field: "edit",
				headerName: "",
				width: 0,
				sortable: false,
				renderCell: (params) => {
					return <IconButton
						size="small"
						onClick={() => { this.props.edit(params.row.questionnaireId) }}
						title="Öppna"
					>
						<EditRounded />
					</IconButton>
				},
			},
			{
				field: "preview",
				headerName: "",
				width: 0,
				sortable: false,
				renderCell: (params) => {
					return <IconButton
						size="small"
						onClick={() => { this.props.preview(params.row.questionnaireId) }}
						title="Förhandsvisa"
					>
						<VisibilityRounded />
					</IconButton>
				},
			},
			{
				field: "calculations",
				headerName: "",
				width: 0,
				sortable: false,
				renderCell: (params) => {
					return <IconButton
						size="small"
						onClick={() => { this.props.openCalculations(params.row.questionnaireId) }}
						title="Beräkningar"
					>
						<FunctionsRounded />
					</IconButton>
				},
			},
			{
				field: "lock",
				headerName: "",
				width: 0,
				sortable: false,
				renderCell: (params) => {
					return <IconButton
						size="small"
						onClick={() => {
							this.setState({
								lockId: params.row.questionnaireId,
							})
						}}
						disabled={params.row.isLocked}
						title={params.row.isLocked ? "Låst" : "Lås"}
					>
					{
						params.row.isLocked
							? <LockRounded />
							: <LockOpenRounded />
					}
					</IconButton>
				},
			},
			{
				field: "identifier",
				headerName: "ID",
				flex: 0.4,
			},
			{
				field: "displayName",
				headerName: "Namn",
				flex: 0.6,
			},
			{
				field: "delete",
				headerName: "",
				width: 0,
				sortable: false,
				renderCell: (params) => {
					return <IconButton
						size="small"
						onClick={() => {
							this.setState({
								deleteId: params.row.questionnaireId,
							})
						}}
						title="Radera"
					>
						<DeleteRounded />
					</IconButton>
				},
			},
		] as GridColDef<ISimpleQuestionnaire>[]

		this.state = {
			columns,
		}
	}

	lock = () => {
		if (!this.state.lockId) {
			return
		}

		this.props.setIsLoading(true)

		QuestionnaireManagementService.lock(this.state.lockId).then(() => {
			this.props.refresh()
		})
		.finally(() => {
			this.setState({
				lockId: undefined,
			})
		})
	}

	delete = () => {
		if (!this.state.deleteId) {
			return
		}

		this.props.setIsLoading(true)

		QuestionnaireManagementService.delete(this.state.deleteId).then(() => {
			this.props.refresh()
		})
		.finally(() => {
			this.setState({
				deleteId: undefined,
			})
		})
	}

	render() {
		const {
			questionnaires,
		} = this.props

		return (
			<>
				<ATable
					columns={this.state.columns}
					rows={questionnaires}
					uniqueKey="questionnaireId"
				/>
				<APopConfirm
					title="LÅS formulär"
					message="När du låst ett formulär kommer du inte längre ha möjlighet att ändra det. Du kan heller inte låsa upp det igen. Är du säker på att du vill låsa detta formulär?"
					onCancel={() => {
						this.setState({
							lockId: undefined,
						})
					}}
					onConfirm={this.lock}
					show={this.state.lockId !== undefined}
				/>
				<APopConfirm
					title="RADERA formulär"
					message="Är du säker på att du vill radera detta formulär? Utskick för detta formulär kommer inte att fungera längre. Du kommer dock fortfarande se svarsresultat för detta formulär."
					onCancel={() => {
						this.setState({
							deleteId: undefined,
						})
					}}
					onConfirm={this.delete}
					show={this.state.deleteId !== undefined}
				/>
			</>
		)
	}
}

export default QuestionnaireList
