import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import React from "react"
import IUser from "../../../api/bo/models/user/IUser"
import ATable from "../../../components/ATable"
import { IconButton } from "@mui/material"
import { DeleteRounded, EditRounded } from "@mui/icons-material"

interface UserListProps {
	users: IUser[]
	edit: (id: string) => void
	// refresh: () => void
	// setIsLoading: (isLoading: boolean) => void
}

interface UserListState {
	columns: GridColDef<IUser>[]
}

class UserList extends React.Component<UserListProps, UserListState> {
	constructor(props: UserListProps) {
		super(props)

		const columns = [
			{
				field: "edit",
				headerName: "",
				width: 0,
				sortable: false,
				renderCell: (params) => {
					return <IconButton
						size="small"
						onClick={() => { this.props.edit(params.row.userId) }}
						title="Öppna"
					>
						<EditRounded />
					</IconButton>
				},
			},
			{
				field: "username",
				headerName: "Login",
				flex: 0.4,
			},
			{
				field: "role",
				headerName: "Roll",
				flex: 0.6,
			},
			{
				field: "createdAt",
				headerName: "Skapad",
				type: "date",
				width: 110,
			},
			// {
			// 	field: "delete",
			// 	headerName: "",
			// 	width: 0,
			// 	sortable: false,
			// 	renderCell: (params) => {
			// 		return <IconButton
			// 			size="small"
			// 			onClick={() => {
			// 				this.setState({
			// 					deleteId: params.row.userId,
			// 				})
			// 			}}
			// 			title="Radera"
			// 		>
			// 			<DeleteRounded />
			// 		</IconButton>
			// 	},
			// },
		] as GridColDef<IUser>[]

		this.state = {
			columns,
		}
	}

	render() {
		return (
			<ATable
				columns={this.state.columns}
				rows={this.props.users}
				uniqueKey="userId"
				sortBy={[{ field: "createdAt", sort: "desc" }]}
			/>
		)
	}
}

export default UserList
