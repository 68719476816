import { TabPanel } from "@mui/lab"
import React, { ReactNode } from "react"

export interface IATabPanelProps {
	value: any
	children: ReactNode
}

class ATabPanel extends React.Component<IATabPanelProps> {
	constructor(props: IATabPanelProps) {
		super(props)
	}

	render() {
		return <TabPanel
			value={this.props.value}
			style={{
				flex: 1,
				// display: "flex",
				flexDirection: "column",
				padding: 0,
			}}
		>
		{
			this.props.children
		}
		</TabPanel>
	}
}

export default ATabPanel
