import { AxiosResponse, AxiosRequestConfig, AxiosInstance, } from "axios"
import AccountService from "../services/AccountService"
import { RequestMethod } from "./RequestMethod"

export default class Rest {
	protected static axiosInstance: AxiosInstance | undefined = undefined
	protected static baseUri = ""

	public static fetch<T>(
		uri: string = "",
		method: RequestMethod = RequestMethod.GET,
		data?: object,
		path?: string[],
		params?: object
	) {
		if (this.axiosInstance === undefined) {
			throw "AxiosUninitialized"
		}

		let finalUri = "/" + this.baseUri

		if (uri) {
			finalUri += "/" + uri
		}

		if (path !== undefined && path.length) {
			finalUri += "/" + path.join("/")
		}

		const token = AccountService.getAuthToken()

		const conf = {
			method: method,
			withCredentials: true,
			params,
			data,
			headers: token
				? { Authorization: "Bearer " + token }
				: undefined,
		} as AxiosRequestConfig

		switch (method) {
			case RequestMethod.POST:
				return this.axiosInstance.post<T>(finalUri, data, conf)
					.then((res) => {
						return this.onRequestSuccess<T>(res)
					}, this.onRequestFailure)
					.catch(this.onRequestFailure)
			case RequestMethod.PUT:
				return this.axiosInstance.put<T>(finalUri, data, conf)
					.then((res) => {
						return this.onRequestSuccess<T>(res)
					}, this.onRequestFailure)
					.catch(this.onRequestFailure)
			case RequestMethod.DELETE:
				return this.axiosInstance.delete<T>(finalUri, conf)
					.then((res) => {
						return this.onRequestSuccess<T>(res)
					}, this.onRequestFailure)
					.catch(this.onRequestFailure)
			default:
				return this.axiosInstance.get<T>(finalUri, conf)
					.then((res) => {
						return this.onRequestSuccess<T>(res)
					}, this.onRequestFailure)
					.catch(this.onRequestFailure)
		}
	}

	static onRequestSuccess<T>(response: AxiosResponse<T, any>) {
		if (response === undefined) {
			throw "RequestFail"
		}

		if (response.status !== 200) {
			throw response
		}

		return response.data as T
	}

	static async onRequestFailure(reason: any) {
		throw reason
		// should be handled by interceptors defined in Wrapper
	}
}
