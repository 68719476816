import React from "react"
import {
	FormGroup
} from "@mui/material"
import IAnswer from "../../../api/bo/models/questionnaire/IAnswer"
import Typer from "../../../components/form/Typer"
import APop from "../../../components/APop"
import APopForm from "../../../components/APopForm"

interface EditAnswerProps {
	answer?: IAnswer
	updateAnswer: (answer: IAnswer) => void
	closeEditAnswerDialog: () => void
	disabled?: boolean
}

interface EditAnswerState {
	text: string
	value: string
}

class EditAnswer extends React.Component<EditAnswerProps, EditAnswerState> {
	constructor(props: EditAnswerProps) {
		super(props)

		const {
			answer,
		} = this.props

		this.state = {
			text: answer?.text ?? "",
			value: answer?.value !== undefined ? answer.value.toString() : "",
		}
	}

	save = () => {
		const {
			answer,
			updateAnswer,
			disabled,
		} = this.props
		const {
			text,
			value,
		} = this.state

		const answerDraft = {...answer!}

		answerDraft.text = text

		try {
			answerDraft.value = parseInt(value)
		}
		catch (e) {}

		updateAnswer(answerDraft)
	}

	renderEditAnswer = () => {
		const {
			answer,
			disabled,
		} = this.props
		const {
			text,
			value,
		} = this.state

		if (answer === undefined) {
			return <></>
		}

		return <FormGroup>
			<Typer
				multiline
				required
				label="Svar"
				type="text"
				value={text}
				onChange={(value) => {
					this.setState({
						text: value,
					})
				}}
				disabled={disabled}
			/>
			<Typer
				required
				label="Värde"
				type="number"
				value={value}
				onChange={(value) => {
					this.setState({
						value: value,
					})
				}}
				description="Svarsalternativets värde. Viktigt för beräkningar. Måste vara numeriskt."
				disabled={disabled}
			/>
		</FormGroup>
	}

	render() {
		const {
			answer,
			closeEditAnswerDialog,
		} = this.props

		return (
			<APopForm
				title="Svarsalternativ"
				show={answer !== undefined}
				onSubmit={this.save}
				onClose={closeEditAnswerDialog}
			>
			{
				this.renderEditAnswer()
			}
			</APopForm>
		)
	}
}

export default EditAnswer
