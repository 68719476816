import React, { ChangeEvent } from "react"
import SendOutService from "../../../services/SendOutService"
import ISimpleQuestionnaire from "../../../api/bo/models/questionnaire/ISimpleQuestionnaire"
import Typer from "../../../components/form/Typer"
import Selecter from "../../../components/form/Selecter"
import APopForm from "../../../components/APopForm"
import IReceiverType from "../../../api/bo/models/receivertype/IReceiverType"
import { FormGroup } from "@mui/material"
import IChangePasswordRequest from "../../../api/bo/models/account/IChangePasswordRequest"
import AccountService from "../../../services/AccountService"

interface ChangePasswordProps {
	onDone: () => void
	onCancel: () => void
	show: boolean
}
interface ChangePasswordState extends IChangePasswordRequest {
	currentPassword: string
	newPassword: string
	confirmNewPassword: string
	isCurrentPasswordValid: boolean
	isNewPasswordValid: boolean
	isConfirmNewPasswordValid: boolean
	validationErrors: { field: string, errors: string[] }[]
}

class ChangePassword extends React.Component<ChangePasswordProps, ChangePasswordState> {
	constructor(props: ChangePasswordProps) {
		super(props)

		this.state = {
			currentPassword: "",
			newPassword: "",
			confirmNewPassword: "",
			isCurrentPasswordValid: false,
			isNewPasswordValid: false,
			isConfirmNewPasswordValid: false,
			validationErrors: [],
		}
	}

	changePassword = () => {
		const {
			onDone,
		} = this.props
		const {
			currentPassword,
			newPassword,
			confirmNewPassword,
			validationErrors,
		} = this.state
		
		AccountService.changePassword(currentPassword, newPassword, confirmNewPassword)
			.then(res => {
				onDone()
			})
			.catch(reason => {
				if (typeof reason === "object") {
					const validationErrors = Object.keys(reason).map((v, i) => {
						return { field: v, errors: (reason[v] as string[]) }
					})
				
					this.setState({
						validationErrors,
					})
				}
			})
	}

	cancel = () => {
		const {
			onCancel,
		} = this.props

		this.setState({
			currentPassword: "",
			newPassword: "",
			confirmNewPassword: "",
		}, onCancel)
	}

	getValidationErrorsForField = (id: string) => {
		const {
			validationErrors,
		} = this.state

		for (let i = 0; i < validationErrors.length; i++) {
			if (validationErrors[i].field === id) {
				return validationErrors[i].errors
			}
		}

		return undefined
	}

	render() {
		const {
			currentPassword,
			newPassword,
			confirmNewPassword,
			validationErrors,
		} = this.state
		const {
			show,
		} = this.props

		return (
			<APopForm
				title="Byt lösenord"
				show={show}
				onSubmit={this.changePassword}
				submitDisabled={!currentPassword.length || !newPassword.length || !confirmNewPassword.length}
				onClose={this.cancel}
			>
				<FormGroup>
					<Typer
						label="Nuvarande lösenord"
						type="password"
						value={currentPassword}
						required
						setIsValid={(isCurrentPasswordValid: boolean) => {
							this.setState({
								isCurrentPasswordValid,
							})
						}}
						onChange={(currentPassword: string) => {
							this.setState({
								currentPassword,
							})
						}}
						errors={this.getValidationErrorsForField("CurrentPassword")}
					/>
					<Typer
						label="Nytt lösenord"
						type="password"
						value={newPassword}
						min={8}
						required
						setIsValid={(isNewPasswordValid: boolean) => {
							this.setState({
								isNewPasswordValid,
							})
						}}
						onChange={(newPassword: string) => {
							this.setState({
								newPassword,
							})
						}}
						errors={this.getValidationErrorsForField("NewPassword")}
					/>
					<Typer
						label="Upprepa nytt lösenord"
						type="password"
						value={confirmNewPassword}
						min={8}
						required
						setIsValid={(isConfirmNewPasswordValid: boolean) => {
							this.setState({
								isConfirmNewPasswordValid,
							})
						}}
						onChange={(confirmNewPassword: string) => {
							this.setState({
								confirmNewPassword,
							})
						}}
						errors={this.getValidationErrorsForField("ConfirmNewPassword")}
					/>
				</FormGroup>
			</APopForm>
		)
	}
}

export default ChangePassword
