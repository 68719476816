import { InputLabel } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import React, { ReactNode } from "react"

interface InputWrapperProps {
	id?: string
	label?: string
	description?: string
	errors?: string[]
	style?: object
	children: ReactNode
}

interface InputWrapperState {
}

class InputWrapper extends React.Component<InputWrapperProps, InputWrapperState> {
	constructor(props: InputWrapperProps) {
		super(props)
	}

	generateHelperText = () => {
		const {
			description,
			errors,
		} = this.props

		let texts = errors ?? (description ? [description] : [])

		return <>
		{
			texts.map((x, i) => {
				return <FormHelperText key={"helper-" + i}
					style={{
						marginLeft: 0,
					}}
				>{x}</FormHelperText>
			})
		}
		</>
	}

	render() {
		const {
			id,
			label,
			description,
			errors,
			style,
			children,
		} = this.props

		return (
			<FormControl
				fullWidth
				style={{
					...style
				}}
				error={errors !== undefined && errors.length > 0}
			>
			{
				label !== undefined && id !== undefined
					? <InputLabel
						id={"label-" + id}
					>
						{label}
					</InputLabel>
					: null
			}
			{
				children
			}
			{
				this.generateHelperText()
			}
			</FormControl>
		)
	}
}

export default InputWrapper
