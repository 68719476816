import React, { ReactNode, useEffect } from "react"
import { Typography, Breadcrumbs } from "@mui/material"
import { Link, Navigate, To } from "react-router-dom"
import AccountService from "../services/AccountService"
import APop, { IAPopProps } from "../components/APop"
import { IAPopAlertProps } from "../components/APopAlert"
import ClientBaseApi from "../api/client/ClientBaseApi"
import AdminBaseApi from "../api/bo/AdminBaseApi"
import IAxiosApiError from "../api/IAxiosApiError"
import AMenu from "../components/AMenu"
import AuthPop from "./authenticate/components/AuthPop"

export interface IWrapperProps {
	children: ReactNode
	title?: string
	subTitle?: string
	progress?: number
	isLoading?: boolean
	setIsLoading?: (isLoading: boolean) => void
	breadcrumbs?: {
		label: string,
		path?: string,
	}[],
	// showBack?: boolean
	className?: string
	contentType?: "data" | "default"
	style?: any
	hideMenu?: boolean
}

interface IWrapperState {
	navigateTo?: To
	applicationError?: {
		error: Error
		errorInfo: React.ErrorInfo
	}
	apiError?: IAxiosApiError
	apiInterceptors: {
		admin: {
			request?: number
			response?: number
		}
		client: {
			request?: number
			response?: number
		}
	}
	critPopProps: IAPopProps
	showAuthPop: boolean
}

class Wrapper extends React.Component<IWrapperProps, IWrapperState> {
	constructor(props: IWrapperProps) {
		super(props)

		this.state = {
			apiInterceptors: {
				admin: {},
				client: {},
			},
			critPopProps: {
				title: "",
				message: "",
				onConfirm: this.onCritPopClose,
				onCancel: undefined, // this.onCritPopClose,
				type: "error",
				show: false,
			} as IAPopAlertProps,
			showAuthPop: false,
		}
	}
	
    componentDidMount() {
		const adminReq = AdminBaseApi.axiosInstance.interceptors.request
			.use(req => { return req }, this.handleApiError)
		const adminResp = AdminBaseApi.axiosInstance.interceptors.response
			.use(res => res, err => { return this.handleApiError(err) })
		
		const clientReq = ClientBaseApi.axiosInstance.interceptors.request
			.use(req => { return req }, this.handleApiError)
		const clientResp = ClientBaseApi.axiosInstance.interceptors.response
			.use(res => res, err => { return this.handleApiError(err) })

		this.setState({
			apiInterceptors: {
				admin: {
					request: adminReq,
					response: adminResp,
				},
				client: {
					request: clientReq,
					response: clientResp,
				}
			}
		})
	}

	componentWillUnmount() {
		const {
			apiInterceptors,
		} = this.state

		if (apiInterceptors.admin.request !== undefined) {
			AdminBaseApi.axiosInstance.interceptors.request
				.eject(apiInterceptors.admin.request)
		}
		if (apiInterceptors.admin.response != undefined) {
			AdminBaseApi.axiosInstance.interceptors.response
				.eject(apiInterceptors.admin.response)
		}
		if (apiInterceptors.client.request !== undefined) {
			ClientBaseApi.axiosInstance.interceptors.request
				.eject(apiInterceptors.client.request)
		}
		if (apiInterceptors.client.response != undefined) {
			ClientBaseApi.axiosInstance.interceptors.response
				.eject(apiInterceptors.client.response)
		}

		this.setState({
			apiInterceptors: {
				admin: {
					request: undefined,
					response: undefined,
				},
				client: {
					request: undefined,
					response: undefined,
				},
			}
		})
	}
	
	componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		const {
			setIsLoading,
		} = this.props
		
		const critPopProps = {
			title: "Applikationsfel",
			message: "Ojdå. Om detta återkommer så vänligen kontakta ansvarig.",
			onConfirm: this.onCritPopClose,
			show: true,
		} as IAPopProps
		
		this.setState({
			applicationError: {
				error,
				errorInfo,
			},
			critPopProps: critPopProps,
		}, () => { setIsLoading && setIsLoading(true) })
	}

	// TODO: breakout
	handleApiError = (error: IAxiosApiError) => {
		const {
			setIsLoading,
		} = this.props
		const {
			apiInterceptors,
		} = this.state

		if (error === undefined) {
			return
		}

		const critPopProps = {
			title: "Okänt fel",
			message: "Var god försök igen eller kontakta ansvarig.",
			onConfirm: this.onCritPopClose,
			show: true,
		} as IAPopProps

		if (error.response !== undefined) {
			switch (error.response.status) {
				case 401: // Unauthorized (Unauthenticated)
					this.setState({
						showAuthPop: true,
					})
					return
					// critPopProps.title = "Åtkomst nekad"
					// critPopProps.message = "Din session verkar ha tagit en promenad, vill du bli kickad till inloggningssidan?"
					// critPopProps.onConfirm = () => {
					// 	AuthService.signOut()
					// }
					// critPopProps.onCancel = this.onCritPopClose
				case 400: // Bad Request
				case 403: // Forbidden
				case 404: // Not Found
				case 405: // Method Not Allowed
				case 418: // I'm a teapot
				case 500: // Internal Server Error
				case 501: // Not Implemented
				case 502: // Bad Gateway
				case 503: // Service Unavailable
					critPopProps.title = "Fel " + error.response.status

					console.log(error.response)
					console.log(typeof error.response.data)

					switch (typeof error.response.data) {
						case "object": // validation error
							if (error.response.data.errors) {
								return error.response.data.errors
							}
							break
						case "string":
							switch (error.response.data) {
								case "QuestionnaireNotLocked":
									critPopProps.title = "Formulär inte låst"
									critPopProps.message = "Det måste låsas innan det kan användas för att samla in svar."
									break
								case "Incomplete":
									critPopProps.title = "Ofullständigt formulär"
									critPopProps.message = "Troligtvis fattas det frågor eller svar."
									break
								case "InvalidUsernameOrPassword":
									critPopProps.title = "Inloggning misslyckades"
									critPopProps.message = "Felaktigt användarnamn eller lösenord."
									break
								case "AlreadySubmitted":
									critPopProps.title = "Redan besvarat"
									critPopProps.message = "Detta formulär har redan besvarats."
									break
								case undefined:
									break
								default:
									critPopProps.message = "Felkod: " + error.response.data
									break
							}
							break
					}
					break
			}
		}
		
		this.setState({
			critPopProps,
			apiError: error,
		}, () => {
			setIsLoading && setIsLoading(true)
		})

		return
	}

	onCritPopClose = () => {
		const {
			setIsLoading,
		} = this.props
		const {
			critPopProps,
		} = this.state

		this.setState({
			critPopProps: {
				...critPopProps,
				show: false,
			} as IAPopAlertProps,
		}, () => {
			setIsLoading && setIsLoading(false)

			setTimeout(() => {
				this.setState({
					critPopProps: {
						title: "",
						message: "",
						onConfirm: this.onCritPopClose,
						onCancel: undefined,
						type: "error",
						show: false,
					} as IAPopAlertProps,
				})
			}, 500)
		})
	}

	renderCrumbs = () => {
		const {
			breadcrumbs,
		} = this.props

		if (!breadcrumbs || !breadcrumbs.length) {
			return
		}

		return <Breadcrumbs
			style={{
				marginTop: "6px",
			}}
		>
		{
			breadcrumbs.map(crumb => {
				return this.renderCrumb(crumb.label, crumb.path)
			})
		}
		</Breadcrumbs>
	}

	renderCrumb = (label: string, path?: string) => {
		if (!label) {
			return
		}

		if (path) {
			return <Link
				to={path}
				key={"breadcrumb-" + label.replace(" ", "")}
				style={{
					fontSize: "1rem",
					lineHeight: "2rem",
					letterSpacing: 1.5,
					textTransform: "uppercase",
					color: "#111111",
				}}
			>
				{label}
			</Link>
		}

		return <Typography
			key={"breadcrumb-" + label.replace(" ", "")}
			style={{
				fontSize: "1rem",
				lineHeight: "2rem",
				letterSpacing: 1.5,
				textTransform: "uppercase",
				color: "#111111",
			}}
		>
			{label}
		</Typography>
	}

	render() {
		const {
			title,
			subTitle,
			progress,
			isLoading,
			setIsLoading,
			breadcrumbs,
			children,
			contentType,
			hideMenu,
			style,
		} = this.props
		const {
			navigateTo,
			critPopProps,
			showAuthPop,
		} = this.state

		const showBackdrop = isLoading

		// I didn't think this would happen in this project,
		// but remove this and it crashes,
		// fix this
		const isSignedIn = AccountService.isAuthenticated()

		return <>
			<header
				style={{
					flex: "none",
					position: "relative",
					height: "51px",
					borderBottom: "solid 1px #3BB2E2", // 4DC8E9
				}}
			>
				<div
					style={{
						maxWidth: "1360px",
						margin: "0 auto",
						position: "relative",
					}}
				>
					<h1
						style={{
							margin: "0",
							// paddingTop: "7px",
							fontSize: "21px",
							lineHeight: "51px",
							letterSpacing: 2,
							position: "absolute",
							left: "17px",
							top: 0,
						}}
					>
						teralyst
					</h1>
				</div>
				{
					progress !== undefined
					&& progress > 0
					&& <div
						style={{
							position: "absolute",
							zIndex: "5",
							left: "0",
							bottom: "-3px",
							height: "5px",
							backgroundColor: "#3BB2E2",
							width: (progress <= 100 ? progress : 100) + "%",
							// boxShadow: "0 0 3px #5A8D9B",
						}}
					/>
				}
			</header>
			<main
				style={{
					// height: "100%", //height: "calc(100% -51px)",
					// maxWidth: "100%",
					// margin: "0 auto",
					display: "flex",
					flex: 1,
					// position: "relative",
				}}
			>
			{
				!hideMenu
				? <div
					style={{
						height: "100%",
						// width: "200px",
						minWidth: 0,
						overflowY: "scroll",
					}}
				>
					<AMenu />
				</div>
				: undefined
			}
				<div
					style={{
						// position: "relative",
						// height: "100%",	
						minWidth: 0,
						margin: "0 15px",
						display: "flex",
						flex: 1,
						flexDirection: "column",
						// alignItems: "stretch",
					}}
				>
					<div
						style={{
							// display: "flex",
							// flexDirection: "column",
							// flex: "none",
							// flex: 1,
							// height: "43px",
							// paddingLeft: "17px",
						}}
					>
					{/* {
						showBack
						&& <div
							style={{
								cursor: "pointer",
								height: "43px",
								overflowY: "hidden",
							}}
							onClick={() => {
								// TODO
							}}
						>
							<ArrowBackIosRounded
								style={{
									// padding: "7px 7px",
									// lineHeight: 1,
									// marginRight: "13px",
									margin: "10px 7px 10px 0",
									width: "27px",
									height: "27px",
								}}
							/>
						</div>
					} */}
					{
						breadcrumbs
							? this.renderCrumbs()
							: undefined
					}
					{
						title
						? <div>
							<Typography
								style={{
									margin: "13px 0",
									marginBottom: subTitle ? "7px" : "13px",
									fontSize: "21px",
									// lineHeight: "21px",
									letterSpacing: 1.5,
									textTransform: "uppercase",
									color: "#111111",
								}}
							>
								{title}
							</Typography>
						</div>
						: undefined
					}
					{
						subTitle
						? <div>
							<Typography
								style={{
									margin: "0",
									marginBottom: contentType == undefined || contentType == "default"
										? 0
										: 17,
									fontSize: "17px",
									lineHeight: "31px",
									// letterSpacing: 1.5,
									color: "#111111",
								}}
							>
								{subTitle}
							</Typography>
						</div>
						: undefined
					}
					</div>
					<div
						style={{
							flex: 1,
							display: "flex",
							flexDirection: "column",
							// height: "100%",
							...style,
						}}
					>
					{
						children
					}
					</div>
				</div>
			</main>
			<div
				style={{	
					position: "absolute",
					zIndex: 10,
					pointerEvents: showBackdrop ? "auto" : "none",
					left: 0,
					top: 0,
					right: 0,
					bottom: 0,
					transition: "backdrop-filter 0.7s",
					backdropFilter: "saturate(180%) blur(" + (showBackdrop ? "5" : "1")
						+ "px) opacity(" + (showBackdrop ? "1" : "0") + ")",
				}}
			>
				<div></div>
			</div>
			<APop
				{...critPopProps}
			/>
			<AuthPop
				setIsLoading={setIsLoading}
				onAuth={() => {
					this.setState({
						showAuthPop: false,
					}, () => {
						setIsLoading && setIsLoading(false)
					})
				}}
				onCancel={() => {
					this.setState({
						showAuthPop: false,
					}, () => {
						setIsLoading && setIsLoading(false)
					})
				}}
				show={showAuthPop}
			/>
		{
			navigateTo !== undefined
				? <Navigate to={navigateTo} />
				: undefined
		}
		</>
	}
}

export default Wrapper
