import { responsiveFontSizes } from "@mui/material"
import AccountApi from "../api/bo/AccountApi"
import { UserRole } from "../api/bo/models/account/UserRole"
import ISignInResponse from "../api/bo/models/account/ISignInResponse"
import IChangePasswordRequest from "../api/bo/models/account/IChangePasswordRequest"
import IUserSettingsRequest from "../api/bo/models/account/IUserSettingsRequest"

export default class AccountService {
	public static checkIfAuthenticated() {
		return AccountApi.isAuthenticated().then(() => {
			if (this.isAuthenticated()) {
				return true
			}

			return false
		})
	}
	
	public static authenticate(username: string, password: string) {
		return AccountApi.auth(username, password).then(resp => {
			const signInResp = resp as ISignInResponse

			AccountService.setAuthed(signInResp.token)
			AccountService.setRole(signInResp.role)

			return true
		})
	}
	
	public static changePassword(currentPassword: string, newPassword: string, confirmNewPassword: string) {
		const request = {
			currentPassword,
			newPassword,
			confirmNewPassword,
		} as IChangePasswordRequest

		return AccountApi.changePassword(request)
	}
	
	public static saveSettings(unsubmittedSendOutsRetentionPeriodInDays: number, submittedSendOutsRetentionPeriodInDays: number) {
		const request = {
			unsubmittedSendOutsRetentionPeriodInDays,
			submittedSendOutsRetentionPeriodInDays,
		} as IUserSettingsRequest

		return AccountApi.saveSettings(request)
	}

	public static getSettings() {
		return AccountApi.getSettings()
	}
	
	public static signOut() {
		AccountService.setUnauthed()

		window.location.href = "/"
	}

	public static getAuthToken(): string | null {
		return localStorage.getItem("AuthToken")
	}

	public static isAuthenticated(): boolean {
		return this.getAuthToken() !== null
	}

	public static setRole(role: UserRole) {
		localStorage.setItem("UserRole", role)
	}

	public static getRole(): UserRole {
		const role = localStorage.getItem("UserRole")

		if (!role) {
			return UserRole.STANDARD
		}

		return role as UserRole
	}

	private static setAuthed(authToken: string) {
		localStorage.setItem("AuthToken", authToken)
	}

	private static setUnauthed() {
		localStorage.removeItem("AuthToken")
		localStorage.removeItem("UserRole")
	}
}
