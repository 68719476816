import React from "react"
import {
	FormGroup,
} from "@mui/material"

import EditAnswer from "./EditAnswer"
import AToolbar from "../../../components/AToolbar"
import IAnswer from "../../../api/bo/models/questionnaire/IAnswer"
import IQuestion from "../../../api/bo/models/questionnaire/IQuestion"
import Typer from "../../../components/form/Typer"
import Selecter from "../../../components/form/Selecter"
import Lister from "../../../components/lister/Lister"
import QuestionType from "../../../api/bo/models/questionnaire/QuestionType"
import { IAButtonProps } from "../../../components/AButton"
import APopAlert, { IAPopAlertProps } from "../../../components/APopAlert"
import { IATabProps } from "../../../components/ATab"
import ATabPanel from "../../../components/ATabPanel"
import { TabContext } from "@mui/lab"

interface ManageQuestionProps {
	isAdding?: boolean
	question: IQuestion
	questions: IQuestion[]
	moveQuestion: (fromOrder: number, toOrder: number) => void
	deleteQuestion?: (order: number) => void
	setQuestionType: (order: number, type: QuestionType) => void
	setQuestionText: (order: number, text: string) => void
	setQuestionIdentifier: (order: number, identifier?: string) => void
	setUseAnswersFrom: (order: number, questionId?: string) => void
	setAnswers: (order: number, answers: IAnswer[]) => void
	disabled?: boolean
}

interface ManageQuestionState {
	activeAnswer?: IAnswer
	activeSection: number
	alertPopProps: IAPopAlertProps
}

class ManageQuestion extends React.Component<ManageQuestionProps, ManageQuestionState> {
	constructor(props: ManageQuestionProps) {
		super(props)

		this.state = {
			activeAnswer: undefined,
			activeSection: 0,
			alertPopProps: {
				title: "",
				message: "",
				onConfirm: this.closePopAlert,
				show: false,
			},
		}
	}

	addAnswer = () => {
		this.setState({
			activeAnswer: {} as IAnswer,
		})
	}

	setAnswer = (answer: IAnswer) => {
		const {
			question,
			setAnswers,
		} = this.props

		const updatedAnswers = question.answers

		if (!answer.order) {
			answer.order = updatedAnswers.length + 1

			updatedAnswers.push(answer)
		}
		else {
			for (let i = 0; i < updatedAnswers.length; i++) {
				if (updatedAnswers[i].order === answer.order) {
					updatedAnswers[i].text = answer.text
					updatedAnswers[i].value = answer.value
				}
			}
		}

		setAnswers(question.order, updatedAnswers)

		this.setState({
			activeAnswer: undefined,
		})
	}

	deleteAnswer = (order: number) => {
		const {
			question,
			setAnswers,
		} = this.props

		const updatedAnswers = question.answers.filter(q => q.order !== order)

		for (let i = 0; i < updatedAnswers.length; i++) {
			updatedAnswers[i].order = i + 1
		}

		setAnswers(question.order, updatedAnswers)
	}

	updateOrderOfAnswers = (answers: IAnswer[]) => {
		const {
			question,
			setAnswers,
		} = this.props

		for (let i = 0; i < answers.length; i++) {
			answers[i].order = i + 1
		}
		
		const orderedAnswers = answers.map(a => {
			return a
		})

		setAnswers(question.order, orderedAnswers)
	}

	closePopAlert = () => {
		this.setState({
			alertPopProps: { ...this.state.alertPopProps, show: false }
		})
	}

	renderAnswers = () => {
		const {
			question,
			questions,
			disabled,
		} = this.props
		const {
			activeAnswer,
		} = this.state

		let answers = question.answers

		if (!!question.derivedAnswersId) {
			const derivedQuestion = questions.find(q => q.questionId === question.derivedAnswersId)

			if (derivedQuestion !== undefined) {
				answers = derivedQuestion.answers
			}
		}

		return <Lister<IAnswer>
			items={answers}
			keyExtractor={(item) => item.order.toString()}
			selectedKeys={
				!!question.derivedAnswersId
					&& activeAnswer !== undefined
					&& activeAnswer.order !== undefined
				? [activeAnswer.order.toString()]
				: []
			}
			labelExtractor={(item) => "#" + item.order + ": " + item.text + " (värde: " + item.value.toString() + ")"}
			onSort={this.updateOrderOfAnswers}
			onClick={(item) => {
				this.setState({
					activeAnswer: item,
				})
			}}
			onDelete={(item) => {
				this.deleteAnswer(item.order)
			}}
			hideCheckbox
			singleSelect
			disabled={!!question.derivedAnswersId || disabled}
		/>
	}

	render() {
		const {
			isAdding,
			question,
			questions,
			moveQuestion,
			setQuestionType,
			setQuestionText,
			setQuestionIdentifier,
			setUseAnswersFrom,
			disabled,
		} = this.props
		const {
			activeAnswer,
		} = this.state

		const otherQuestionsWithAnswers = questions.filter(q =>
			q.questionId !== undefined
			&& q.type !== QuestionType.FreeText
			&& !q.derivedAnswersId // can be null or maybe undefined
			&& q.questionId !== question.questionId
		)
		
		let otherQuestionsUsingTheseAnswers: IQuestion[] = []
		
		if (question.questionId !== undefined) {
			otherQuestionsUsingTheseAnswers = questions.filter(q =>
				q.derivedAnswersId === question.questionId
			)
		}

		const hasDerivedAnswers = question.derivedAnswersId !== null && question.derivedAnswersId !== undefined

		// const baseToolbarButtons: IAButtonProps[] = [
		// 	{
		// 		label: "radera fråga",
		// 		color: "secondary",
		// 		variant: "text",
		// 		action: deleteQuestion,
		// 	},
		// 	{
		// 		label: "skapa kopia av fråga",
		// 		action: () => {
		// 		},
		// 		disabled: true,
		// 	},
		// ]
		const questionTypeTabs: IATabProps[] = [
			{
				label: "fritext",
				action: () => {
					setQuestionType(question.order, QuestionType.FreeText)
				},
				value: QuestionType.FreeText,
				disabled: disabled,
			},
			{
				label: "enval",
				action: () => {
					setQuestionType(question.order, QuestionType.SingleChoice)
				},
				value: QuestionType.SingleChoice,
				disabled: disabled,
			},
		]

		const singleChoiceToolbarTabs: IATabProps[] = [
			{
				label: "ärv svar",
				value: (true).toString(),
				disabled: disabled,
			},
			{
				label: "egna svar",
				value: (false).toString(),
				disabled: disabled,
			},
		]

		const setUseDerivedAnswers = (deriveAnswers: string) => {
			if (deriveAnswers === "true") {
				if (!otherQuestionsWithAnswers.length) {
					this.setState({
						alertPopProps: {
							...this.state.alertPopProps,
							title: "Ingen att ärva från",
							message: "Det finns inga andra frågor som har några egna svar. Lägg till svar på en annan fråga först för att kunna återanvända dess svar för denna fråga. Har du lagt till en ny fråga med egna svarsalternativ så testa att spara formuläret först så ska det nog gå sedan.",
							show: true,
						}
					})
				}
				else if (otherQuestionsUsingTheseAnswers.length) {
					const otherQuestions: string[] = []

					for (let i in otherQuestionsUsingTheseAnswers) {
						otherQuestions.push("#" + otherQuestionsUsingTheseAnswers[i].order)
					}

					this.setState({
						alertPopProps: {
							...this.state.alertPopProps,
							title: "Andra frågor ärver denna",
							message: "Det finns andra frågor som använder den här frågans svar. Ta bort arvet från de frågorna innan du raderar eller gör om svarstypen för denna frågan. Frågorna i fråga är: " + otherQuestions.join(", "),
							show: true,
						}
					})
				}
				else {
					setUseAnswersFrom(
						question.order,
						otherQuestionsWithAnswers[otherQuestionsWithAnswers.length - 1].questionId
					)
				}
			}
			else {
				setUseAnswersFrom(question.order, undefined)
			}
		}

		const singleChoiceToolbarButtons: IAButtonProps[] = [
			{
				label: "svar",
				type: "add",
				action: this.addAnswer,
				disabled: disabled || hasDerivedAnswers,
			},
		]

		const placeQuestionOptions = questions.map(q => {
			return {
				key: "placequestion-" + q.questionId,
				value: q.order.toString(),
				disabled: q.order === question.order,
				label: "#" + q.order,
			}
		})

		if (isAdding) {
			placeQuestionOptions.push({
				key: "placequestion-last",
				value: (questions.length + 1).toString(),
				disabled: question.order === questions.length + 1,
				label: "sist",
			})
		}

		return (
			<div>
			{
				<FormGroup>
					<Typer
						label="ID"
						type="text"
						value={question.identifier}
						onChange={x => {
							setQuestionIdentifier(question.order, x)
						}}
						disabled={disabled}
					/>
					<Typer
						multiline
						required
						label="Fråga"
						type="text"
						value={question.text}
						onChange={x => {
							setQuestionText(question.order, x)
						}}
						disabled={disabled}
					/>
					<Selecter
						label="Placering"
						value={question.order.toString()}
						options={placeQuestionOptions}
						onSelect={x => {
							try {
								moveQuestion(
									question.order,
									parseInt(x as string),
								)
							}
							catch (e) {}
						}}
						disabled={disabled}
					/>
				</FormGroup>
			}
			{
				<TabContext
					value={question.type}
				>
					<FormGroup>
						<AToolbar
							tabs={questionTypeTabs}
							activeTab={question.type}
							setActiveTab={(value) => {
								setQuestionType(question.order, value)
							}}
						/>
						<ATabPanel
							value={QuestionType.SingleChoice}
						>
							<AToolbar
								tabs={singleChoiceToolbarTabs}
								activeTab={hasDerivedAnswers.toString()}
								setActiveTab={setUseDerivedAnswers}
								buttons={singleChoiceToolbarButtons}
							/>
						{
							!!question.derivedAnswersId
							? <Selecter
								label="Välj fråga att använda svar från"
								value={question.derivedAnswersId}
								options={otherQuestionsWithAnswers.map(q => {
									return {
										key: "useanswersfrom-" + q.questionId!,
										value: q.questionId!,
										label: "#" + q.order,
									}
								})}
								onSelect={(val) => {
									setUseAnswersFrom(question.order, !val.length
										? undefined
										: val as string)
								}}
								description="Om denna fråga ska använda samma svarsalternativ som någon annan fråga så välj den här."
								disabled={disabled}
							/>
							: undefined
						}
						{
							this.renderAnswers()
						}
						</ATabPanel>
					</FormGroup>
				</TabContext>
			}
			{
				activeAnswer !== undefined
				? <EditAnswer
					closeEditAnswerDialog={() => {
						this.setState({
							activeAnswer: undefined,
						})
					}}
					answer={activeAnswer}
					updateAnswer={this.setAnswer}
					disabled={disabled}
				/>
				: undefined
			}
				<APopAlert
					{...this.state.alertPopProps}
				/>
			</div>
		)
	}
}

export default ManageQuestion
