import React from "react"
import ICalculation from "../../../../api/bo/models/questionnaire/ICalculation"
import CalculationService from "../../../../services/CalculationService"
import CalculationBase from "./CalculationBase"
import APopForm from "../../../../components/APopForm"

interface CreateCalculationProps {
	onCreated: (id: string) => void
	onCancel: () => void
	showDialog: boolean
	questionnaireId?: string
}
interface CreateCalculationState {
	calculation: ICalculation
	error: string
}

class CreateCalculation extends React.Component<CreateCalculationProps, CreateCalculationState> {
	constructor(props: CreateCalculationProps) {
		super(props)

		this.state = {
			calculation: {
				identifier: "",
			} as ICalculation,
			error: "",
		}
	}

	create = () => {
		const {
			calculation,
		} = this.state
		const {
			onCreated,
			questionnaireId,
		} = this.props

		if (!calculation.identifier.length) {
			this.setState({
				error: "Vänligen, ange Namn för att skapa beräkning",
			})

			return
		}

		if (!questionnaireId) {
			return
		}

		CalculationService.create(questionnaireId, calculation.identifier).then(id => {
			if (id !== undefined) {
				this.clear(() => {
					onCreated(id)
				})
			}
		})
	}

	setIdentifier = (identifier: string, callback?: () => void) => {
		this.setState(prevState => ({
			calculation: {
				...prevState.calculation,
				identifier,
			} as ICalculation,
		}), callback)
	}

	clear = (callback?: () => void) => {
		this.setState({
			error: "",
		}, () => {
			this.setIdentifier("", callback)
		})
	}

	cancel = () => {
		const {
			onCancel,
		} = this.props

		this.clear(onCancel)
	}

	render() {
		const {
			calculation,
			error,
		} = this.state
		const {
			showDialog,
		} = this.props

		return (
			<APopForm
				title="Skapa beräkning"
				show={showDialog}
				onSubmit={this.create}
				submitDisabled={!calculation.identifier.length}
				onClose={this.cancel}
			>
				<CalculationBase
					setIdentifier={this.setIdentifier}
					calculation={calculation}
					identifier={calculation.identifier}
				/>
			</APopForm>
		)
	}
}

export default CreateCalculation
