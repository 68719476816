import React, { useState, useEffect } from "react"
import {
	useNavigate,
} from "react-router-dom"
import {
	EditRounded,
	AddRounded,
	DeleteRounded,
} from "@mui/icons-material"
import AToolbar from "../../components/AToolbar"

import CaseService from "../../services/CaseService"
import ICase from "../../api/bo/models/case/ICase"
import Wrapper from "../Wrapper"
import CaseList from "./components/CaseList"
import CreateCase from "./components/CreateCase"
import { IAButtonProps } from "../../components/AButton"
import APopConfirm from "../../components/APopConfirm"

const CaseOverviewScreen = () => {
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState<boolean>(true)

	const [cases, setCases] = useState<Array<ICase>>([])
	const [isCreateCaseDialogOpen, setIsCreateCaseDialogOpen] = useState<boolean>(false)

	useEffect(() => {
		refresh()
	}, [])

	const refresh = () => {
		setIsLoading(true)

		CaseService.getAll().then(cases => {
			if (Array.isArray(cases)) {
				setCases(cases)
			}

			setIsLoading(false)
		})
	}

	const toolbarButtons: Array<IAButtonProps> = [
		{
			label: "ärende",
			action: () => {
				setIsCreateCaseDialogOpen(true)
			},
			icon: <AddRounded />,
			type: "add",
		},
	]

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			breadcrumbs={[
				{
					label: "start",
					path: "/"
				},
				{
					label: "ärenden",
				}
			]}
		>
			<AToolbar
				buttons={toolbarButtons}
			/>
			<CaseList
				cases={cases}
				edit={(caseId) => {
					navigate("/case/" + caseId, {
						replace: false,
					})
				}}
				refresh={refresh}
				setIsLoading={setIsLoading}
			/>
			<CreateCase
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				showDialog={isCreateCaseDialogOpen}
				onCancel={() => {
					setIsCreateCaseDialogOpen(false)
				}}
				onCreated={(id: string) => {
					refresh()
					setIsCreateCaseDialogOpen(false)
				}}
			/>
		</Wrapper>
	)
}

export default CaseOverviewScreen
