import React, { useState, useEffect } from "react"
import {
	useNavigate,
	useParams,
} from "react-router-dom"
import {
	EditRounded,
	AddRounded,
} from "@mui/icons-material"
import AToolbar from "../../../components/AToolbar"
import QuestionnaireManagementService from "../../../services/QuestionnaireManagementService"
import CalculationList from "./components/CalculationList"
import CalculationService from "../../../services/CalculationService"
import CreateCalculation from "./components/CreateCalculation"
import Wrapper from "../../Wrapper"
import ICalculation from "../../../api/bo/models/questionnaire/ICalculation"
import IQuestionnaire from "../../../api/bo/models/questionnaire/IQuestionnaire"
import { IAButtonProps } from "../../../components/AButton"

const CalculationOverviewScreen = () => {
	const navigate = useNavigate()

	const { questionnaireId } = useParams<"questionnaireId">()

	const [isLoading, setIsLoading] = useState<boolean>(true)

	const [questionnaire, setQuestionnaire] = useState<IQuestionnaire>()
	const [calculations, setCalculations] = useState<Array<ICalculation>>([])
	
	const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false)

	useEffect(() => {
		refresh()
	}, [])

	function refresh() {
		if (!questionnaireId) {
			return
		}

		setIsLoading(true)

		Promise.all([
			QuestionnaireManagementService.get(questionnaireId),
			CalculationService.getAllForQuestionnaire(questionnaireId),
		]).then(values => {
			const questionnaireResponse = values[0]
			const calculationsResponse = values[1]

			setIsLoading(false)

			if (!questionnaireResponse || !calculationsResponse) {
				return
			}

			setQuestionnaire(questionnaireResponse)
			setCalculations(calculationsResponse)
		})
	}

	const toolbarButtons: Array<IAButtonProps> = [
		{
			label: "ny beräkning",
			action: () => {
				setIsCreateDialogOpen(true)
				//navigate("/questionnaire/" + questionnaireId + "/calculation/new")
			},
			icon: <AddRounded />,
			type: "add",
		},
	]

	return (
		<Wrapper
			isLoading={isLoading}
			setIsLoading={setIsLoading}
			breadcrumbs={[
				{
					label: "start",
					path: "/"
				},
				{
					label: "formulär",
					path: "/questionnaires",
				},
				{
					label: !questionnaire
						? "..."
						: questionnaire.identifier,
					path: !questionnaire
						? undefined
						: "/questionnaire/" + questionnaire.questionnaireId,
				},
				{
					label: "beräkningar",
				}
			]}
		>
			<AToolbar
				buttons={toolbarButtons}
			/>
			<CalculationList
				calculations={calculations}
				edit={(calculationId) => {
					navigate("/questionnaire/" + questionnaireId + "/calculation/" + calculationId, {
						replace: false,
					})
				}}
				refresh={refresh}
				setIsLoading={setIsLoading}
			/>
			<CreateCalculation
				questionnaireId={questionnaireId}
				onCreated={(id: string) => {
					setIsCreateDialogOpen(false)
					refresh()
				}}
				showDialog={isCreateDialogOpen}
				onCancel={() => {
					setIsCreateDialogOpen(false)
				}}
			/>
		</Wrapper>
	)
}

export default CalculationOverviewScreen
