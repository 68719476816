import ISignInResponse from "./models/account/ISignInResponse"
import AdminBaseApi from "./AdminBaseApi"
import { RequestMethod } from "../RequestMethod"
import IChangePasswordRequest from "./models/account/IChangePasswordRequest"
import IUserSettingsRequest from "./models/account/IUserSettingsRequest"
import IUserSettingsResponse from "./models/account/IUserSettingsResponse"

export default class AccountApi extends AdminBaseApi {
	protected static baseUri = "account"

	public static isAuthenticated() {
		return this.fetch<boolean>("isauthenticated", RequestMethod.GET)
	}

	public static auth(username: string, password: string) {
		const data = {
			username,
			password,
		}

		return this.fetch<ISignInResponse>("signin", RequestMethod.POST, data)
	}

	public static unauth() {
		return this.fetch("logout", RequestMethod.POST)
	}

	public static changePassword(data: IChangePasswordRequest) {
		return this.fetch("changepassword", RequestMethod.POST, data)
	}

	public static getSettings() {
		return this.fetch<IUserSettingsResponse>("settings", RequestMethod.GET)
	}

	public static saveSettings(data: IUserSettingsRequest) {
		return this.fetch("settings", RequestMethod.POST, data)
	}

	// public static externalLoginCallback() {
	// 	const ur = ApiLinks.BACK_OFFICE.ACCOUNT.EXTERNAL_LOGIN_CALLBACK

	// 	return this.get(ur).then((response) => {
	// 	})
	// }

	// public static bankIdSameDevice(returnUrl: string) {
	// 	const ur = ApiLinks.BACK_OFFICE.ACCOUNT.BANKID_SAME_DEVICE

	// 	return this.get(ur, undefined, { returnUrl }).then((response) => {
	// 		// return response
	// 	})
	// }

	// public static bankIdOtherDevice(returnUrl: string) {
	// 	const ur = ApiLinks.BACK_OFFICE.ACCOUNT.BANKID_OTHER_DEVICE

	// 	return this.get(ur, undefined, { returnUrl }).then((response) => {
	// 		// return response
	// 	})
	// }
}
