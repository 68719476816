import React, { useState, useEffect } from "react"
import {
	useNavigate,
	useParams,
} from "react-router-dom"
import FormBase from "./components/FormBase"
import AddQuestion from "./components/AddQuestion"
import SortQuestions from "./components/SortQuestions"
import IQuestionnaire from "../../api/bo/models/questionnaire/IQuestionnaire"
import IQuestion from "../../api/bo/models/questionnaire/IQuestion"
import QuestionnaireManagementService from "../../services/QuestionnaireManagementService"
import Wrapper from "../Wrapper"
import { IAButtonProps } from "../../components/AButton"
import AToolbar from "../../components/AToolbar"
import IClientSubmitAnswer from "../../api/client/models/IClientSubmitAnswer"
import IClientQuestion from "../../api/client/models/IClientQuestion"
import IClientAnswer from "../../api/client/models/IClientAnswer"
import SinglePage from "../answer/components/SinglePage"
import { IATabProps } from "../../components/ATab"
import { TabContext, TabPanel } from "@mui/lab"
import ATabPanel from "../../components/ATabPanel"

export enum QuestionnaireScreenTab {
	Info = "info",
	Questions = "questions",
	Preview = "preview",
}

const QuestionnaireScreen = () => {
	const navigate = useNavigate()

	const { questionnaireId } = useParams<"questionnaireId">()

	const getActiveTab = () => {
		const fragment = window.location.hash.replace("#", "")

		return fragment ? fragment as QuestionnaireScreenTab : QuestionnaireScreenTab.Info
	}

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [activeTab, setActiveTab] = useState<QuestionnaireScreenTab>(getActiveTab())

	const [questionnaire, setQuestionnaire] = useState<IQuestionnaire | undefined>(undefined)
	const [draft, setDraft] = useState<IQuestionnaire>({
		questionnaireId: undefined,
		identifier: "",
		displayName: "",
		displayDescription: "",
		lockedAt: undefined,
		hiddenAt: undefined,
		questions: new Array<IQuestion>(),
	} as IQuestionnaire)

	const [previewProvidedAnswers, setPreviewProvidedAnswers] = useState<IClientSubmitAnswer[]>([])

	const [isBaseFormValid, setIsBaseFormValid] = useState<boolean>(false)

	const [activeQuestionOrder, setActiveQuestionOrder] = useState<number | undefined>(undefined)

	const [isAddQuestionDialogOpen, setAddQuestionDialogOpen] = useState<boolean>(false)

	const [isSaved, setIsSaved] = useState<boolean>(true)

	// const [hasChanges, setHasChanges] = useState<boolean>(false)

	const disableEdit = !!draft.lockedAt

	useEffect(() => {
		navigate("#" + activeTab)
	}, [activeTab])

	useEffect(() => {
		if (questionnaireId === undefined) {
			setQuestionnaire({...draft})
		}
	}, [draft])

	useEffect(() => {
		setIsLoading(true)

		if (questionnaireId === undefined) {
			setQuestionnaire({...draft})
			setIsLoading(false)
		}
		else {
			QuestionnaireManagementService.get(questionnaireId).then(res => {
				const questionnaireResp = res as IQuestionnaire
				
				setDraft(questionnaireResp)

				if (questionnaireResp.questions.length) {
					setActiveQuestionOrder(questionnaireResp.questions[0].order)
				}

				setIsBaseFormValid(true)
				setIsLoading(false)
			})
		}
	}, [])

	const refresh = () => {
		setIsLoading(true)
		
		if (questionnaireId == undefined) {
			setIsLoading(false)
		}
		else {
			QuestionnaireManagementService.get(questionnaireId).then(res => {
				if (res !== undefined) {
					setDraft(res)
				}
				else {
					// handle
				}
	
				setIsLoading(false)
			})
		}
	}

	const setQuestionText = (order: number, text: string) => {
		const draftQuestions = [...draft.questions]

		draftQuestions.forEach((q, i) => {
			if (q.order === order) {
				q.text = text
			}
		})

		updateQuestions(draftQuestions)
	}
	
	const setUseAnswersFrom = (order: number, questionId?: string) => {
		const draftQuestions = [...draft.questions]

		draftQuestions.forEach((q, i) => {
			if (q.order === order) {
				q.derivedAnswersId = questionId
			}
		})

		updateQuestions(draftQuestions)
	}

	const updateQuestions = (questions: IQuestion[], draftActiveQuestionOrder?: number) => {
		questions.sort((a, b) => {
			return a.order - b.order
		})

		// fix gaps in order caused by delete
		for (let i = 0; i < questions.length; i++) {
			questions[i].order = i + 1
		}
		
		updateDraft("questions", questions)
		
		if (draftActiveQuestionOrder === undefined) {
			return
		}

		setActiveQuestionOrder(draftActiveQuestionOrder)
	}

	const updateDraft = (key: string, value: any) => {
		setIsSaved(false)
		setDraft({
			...draft,
			[key]: value,
		})
	}
	
	const getActiveQuestion = () => {
		return draft.questions.find(q => q.order === activeQuestionOrder)
	}

	const addQuestion = (question: IQuestion) => {
		let draftQuestions = [...draft.questions]

		for (let i in draftQuestions) {
			if (draftQuestions[i].order >= question.order) {
				draftQuestions[i].order++
			}
		}

		draftQuestions.push(question)
		
		updateQuestions(draftQuestions, question.order)
		setAddQuestionDialogOpen(false)
	}

	const moveQuestion = (fromOrder: number, toOrder: number) => {
		if (fromOrder === toOrder) {
			return
		}

		const questions = draft.questions
		const movingBack = toOrder < fromOrder

		const question = questions.splice(questions.findIndex(q => q.order === fromOrder), 1)[0]

		for (let i = 0; i < questions.length; i++) {
			const order = questions[i].order

			if (movingBack) {
				if (order < fromOrder && order >= toOrder) {
					questions[i].order++
				}
			}
			else {
				if (order > fromOrder && order <= toOrder) {
					questions[i].order--
				}
			}
		}

		question.order = toOrder

		questions.push(question)

		updateQuestions(questions, toOrder)
	}
	
	const deleteQuestion = (order: number) => {
		let draftQuestions = [...draft.questions]

		const index = draftQuestions.findIndex(q => q.order === order)

		draftQuestions.splice(index, 1)

		let draftActiveOrder = draftQuestions.findIndex(q => q.order === order - 1)
		
		updateQuestions(draftQuestions, draftActiveOrder)
	}

	const findHighestQuestionOrder = () => {
		return (draft.questions as any).at(draft.questions.length - 1)?.order
	}
	
	const isValid = () => {
		if (!isBaseFormValid) {
			return false
		}
	}

	const setAnswer = (answer: IClientSubmitAnswer) => {
		const updatedProvidedAnswers = previewProvidedAnswers.map(a => {
			if (a.questionId === answer.questionId) {
				return answer
			}
			return a
		})

		if (!previewProvidedAnswers.some(a => a.questionId === answer.questionId)) {
			updatedProvidedAnswers.push(answer)
		}

		setPreviewProvidedAnswers(updatedProvidedAnswers)
	}

	const pickAnswer = (questionId: string, answerId: string) => {
		setAnswer({
			questionId,
			answerId,
		})
	}

	const setFreeTextAnswer = (questionId: string, text: string) => {
		setAnswer({
			questionId,
			text,
		})
	}

	const save = () => {
		setIsLoading(true)

		if (questionnaireId == undefined) {
			QuestionnaireManagementService.create(draft).then(id => {
				window.location.href = "/questionnaire/" + id
			})
		}
		else {
			QuestionnaireManagementService.update(questionnaireId, draft).then(id => {
				refresh()
				setIsSaved(true)
			})
		}
	}
	
	const questionsToClientQuestions = () => {
		const clientQuestions: IClientQuestion[] = []
		
		for (let i = 0; i < draft.questions.length; i++) {
			const question = draft.questions[i]

			if (question.questionId === undefined) {
				continue
			}

			let answers = question.answers

			if (question.derivedAnswersId) {
				const deriveAnswersFrom = draft.questions.find(q => q.questionId === question.derivedAnswersId)

				if (deriveAnswersFrom) {
					answers = deriveAnswersFrom.answers
				}
			}

			clientQuestions.push({
				questionId: question.questionId,
				identifier: question.identifier,
				order: question.order,
				type: question.type,
				text: question.text,
				answers: answers.map(a => {
					return {
						answerId: a.answerId,
						order: a.order,
						text: a.text,
						value: a.value.toString(),
					} as IClientAnswer
				})
			})
		}
		
		return clientQuestions
	}

	const toolbarTabs: Array<IATabProps> = [
		{
			label: "info",
			value: QuestionnaireScreenTab.Info,
		},
		{
			label: "frågor",
			value: QuestionnaireScreenTab.Questions,
		},
		{
			label: "förhandsvisa",
			value: QuestionnaireScreenTab.Preview,
			disabled: !isSaved,
		},
	]

	const toolbarButtons: Array<IAButtonProps> = [
		{
			label: "fråga",
			action: () => {
				setAddQuestionDialogOpen(true)
			},
			type: "add",
			disabled: disableEdit,
			hidden: activeTab !== QuestionnaireScreenTab.Questions,
		},
		{
			label: disableEdit
				? "formulär låst"
				: isSaved && questionnaireId !== undefined
					? "allt sparat"
					: "spara ändringar",
			action: save,
			type: "save",
			disabled: isSaved || disableEdit, // || !isValid(),
			hidden: activeTab === QuestionnaireScreenTab.Preview,
		},
	]

	const breadcrumbs: { label: string, path: string | undefined }[] = [
		{
			label: "start",
			path: "/",
		},
		{
			label: "formulär",
			path: "/questionnaires",
		}
	]

	if (questionnaireId === undefined) {
		breadcrumbs.push({
			label: "nytt",
			path: undefined,
		})
	}
	else {
		breadcrumbs.push({
			label: draft.identifier === undefined
				? "..."
				: draft.identifier,
			path: undefined,
		})
	}

	return (
		<Wrapper
			breadcrumbs={breadcrumbs}
			isLoading={isLoading}
			setIsLoading={setIsLoading}
		>
			<AToolbar
				tabs={toolbarTabs}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				buttons={toolbarButtons}
			/>
			<TabContext
				value={activeTab}
			>
				<ATabPanel
					value={QuestionnaireScreenTab.Info}
				>
					<FormBase
						identifier={draft.identifier}
						displayName={draft.displayName}
						displayDescription={draft.displayDescription}
						setIdentifier={v => updateDraft("identifier", v)}
						setDisplayName={v => updateDraft("displayName", v)}
						setDisplayDescription={v => updateDraft("displayDescription", v)}
						setFormValid={setIsBaseFormValid}
						disabled={disableEdit}
					/>
				</ATabPanel>
				<ATabPanel
					value={QuestionnaireScreenTab.Questions}
				>
					<SortQuestions
						questions={draft.questions}
						deleteQuestion={deleteQuestion}
						updateQuestions={updateQuestions}
						setUseAnswersFrom={setUseAnswersFrom}
						editQuestion={(order) => {
							setActiveQuestionOrder(order)
						}}
						activeQuestionOrder={activeQuestionOrder}
						setActiveQuestionOrder={setActiveQuestionOrder}
						getActiveQuestion={getActiveQuestion}
						moveQuestion={moveQuestion}
						openAddQuestionDialog={() => setAddQuestionDialogOpen(true)}
						setQuestionText={setQuestionText}
						disabled={disableEdit}
					/>
				</ATabPanel>
				<ATabPanel
					value={QuestionnaireScreenTab.Preview}
				>
					<SinglePage
						questions={questionsToClientQuestions()}
						providedAnswers={previewProvidedAnswers}
						pickAnswer={pickAnswer}
						setFreeTextAnswer={setFreeTextAnswer}
						showQuestionIdentifier={true}
					/>
				</ATabPanel>
			</TabContext>
			{
				isAddQuestionDialogOpen
				&& <AddQuestion
					questions={draft.questions}
					addQuestion={addQuestion}
					isAddQuestionDialogOpen={isAddQuestionDialogOpen}
					closeAddQuestionDialog={() => {
						setAddQuestionDialogOpen(false)
					}}
				/>
			}
		</Wrapper>
	)
}

export default QuestionnaireScreen
