import React, { forwardRef, ReactElement } from "react"
import {
	ControlCameraRounded,
	DeleteRounded,
	CheckBoxOutlineBlankRounded,
	CheckBoxRounded,
} from "@mui/icons-material"
import { Typography } from "@mui/material"
import APopConfirm from "../APopConfirm"

interface ListeringProps<T> {
	item: T
	id: string
	label: string
	onClick?: () => void
	onSelect?: () => void
	onDelete?: () => void
	activeContentRenderer?: () => ReactElement
	active?: boolean
	selected?: boolean
	disabled?: boolean
	sortable?: boolean
	hideCheckbox?: boolean
	style?: any
}

interface ListeringState {
	showConfirmDelete?: boolean
}

class Listering<T> extends React.Component<ListeringProps<T>, ListeringState> {
	constructor(props: ListeringProps<T>) {
		super(props)

		this.state = {
		}
	}

	render() {
		const {
			id,
			label,
			selected,
			onClick,
			onDelete,
			onSelect,
			activeContentRenderer,
			active,
			disabled,
			sortable,
			hideCheckbox,
			style,
		} = this.props
		const {
			showConfirmDelete,
		} = this.state

		return (
			<div
				key={id}
				style={{
					borderRadius: "2px",
					boxShadow: selected
						? "1px 1px 3px rgba(0, 0, 0, 0.7)"
						: "1px 1px 2px rgba(0, 0, 0, 0.5)",
					margin: "6px 0",
					display: "flex",
					flexDirection: "column",
					opacity: disabled
						? "0.49"
						: "1",
					...style,
				}}
			>
				<div
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "row",
						alignItems: "stretch",
						minHeight: "34px",
					}}
				>
				{
					onSelect !== undefined
						&& !hideCheckbox
					? <div
						className="selecter"
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "34px",
							aspectRatio: 1,
							cursor: !disabled
								? "pointer"
								: undefined,
						}}
						onClick={onSelect}
					>
					{
						selected
							? <CheckBoxRounded />
							: <CheckBoxOutlineBlankRounded />
					}
					</div>
					: undefined
				}
				{
					sortable
					? <div
						className="dragger"
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "34px",
							cursor: "move",
						}}
					>
						<ControlCameraRounded />
					</div>
					: undefined
				}
					<div
						style={{
							display: "flex",
							flex: 1,
							alignItems: "center",
							paddingLeft: (sortable || (onSelect !== undefined && !hideCheckbox))
								? "0"
								: "8.5px",
							cursor: !disabled && (onClick !== undefined || onSelect !== undefined)
								? "pointer"
								: undefined,
							minWidth: "0",
						}}
						onClick={() => {
							if (onClick !== undefined) {
								onClick()
							}
							else if (onSelect !== undefined) {
								onSelect()
							}
						}}
					>
						<Typography
							variant="body1"
							component="span"
							style={{
								// display: "inline-block",
								margin: "7px 0 5px",
								fontSize: "large",
								lineHeight: 1.2,
								overflow: "hidden",
								whiteSpace: "nowrap",
								textOverflow: "ellipsis",
							}}
						>
							{label}
						</Typography>
					</div>
					{
						onDelete !== undefined
						? <div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								width: "34px",
							}}
							onClick={() => {
								if (disabled) {
									return
								}

								this.setState({
									showConfirmDelete: true,
								})
							}}
						>
							<DeleteRounded />
						</div>
						: undefined
					}
				</div>
			{
				active
				&& activeContentRenderer !== undefined
				&& <div
					style={{
						borderTop: "solid 1px #aaaaaa",
						padding: "8.5px",
					}}
				>
				{
					activeContentRenderer !== undefined
						? activeContentRenderer()
						: undefined
				}
				</div>
			}
			{
				onDelete !== undefined
				? <APopConfirm
					title="Radera"
					message="Är du säker på att du vill radera denna?"
					onConfirm={() => {
						this.setState({
							showConfirmDelete: false,
						}, onDelete)
					}}
					onCancel={() => {
						this.setState({
							showConfirmDelete: false,
						})
					}}
					show={!!showConfirmDelete}
				/>
				: undefined
			}
			</div>
		)
	}
}

export default Listering
